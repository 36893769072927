import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import SmoothScroll from 'smooth-scroll';
import { config, carFields } from '@/utils';

import {
  CarCard,
  CarGallery,
  CarOptions,
  CarComment,
  CarTitle,
  CarFooter,
  CarInfo,
  CarInfoFinance,
  CarInfoCredit,
  CarInfoProperties,
  CarInfoActions,
  Button,
  getSpecificationsForGroup,
  Loader,
  Title,
} from 'azgaz-ui';

import { hooks as hooksConnect } from 'azgaz-connect';
import { servicesActions } from '@/apps/appCreator/store/services';
import { useDealerOffices, useCarModels } from '@/hooks';
import useBookScrollByQueryParam from '@/hooks/useBookScrollByQueryParam';
import { ErrorBoundary } from 'react-error-boundary';
import { State } from '../../store';
import {
  getCarPriceWithDiscounts,
  getCarPriceInfo,
  getCarModelImage,
} from '../../selectors';
import { CarContext } from '../../contexts';
import CarBackButton from '../CarBackButton';
import CarVinInfo from '../CarVinInfo';
import CarInfoPrice from '../CarInfoPrice';
import CarDisclaimer from '../CarDisclaimer';
import CarPresentationLink from '../CarPresentationLink';
import CarTestTruckLink from '../CarTestTruckLink';
import CarServicesList from '../CarServicesList';
import CarForms from '../CarForms';
import CarsRelated from '../CarsRelated';
import CarsSeen from '../CarsSeen';
import LayoutMain from '../LayoutMain';
import FavouriteBtn from '../CarsItemFavouriteBtn';
import CarBar from '../CarBar';
import CarHeaderFixed from '../CarHeaderFixed';

import style from './style.module.scss';

const smoothScroll = new SmoothScroll(undefined, {
  header: '.azgazui__car-header-fixed',
  updateURL: false,
});

const Car: FC = () => {
  const store = useStore();
  const dispatch = useDispatch();
  useCarModels();

  /**
   * Формирование данных для контекста carContext
   */
  const { id } = useParams();
  const { car } = hooksConnect.useCarFromStoreOrApi(id);

  const carPriceInfo = getCarPriceInfo(car);

  const carPriceWithDiscounts = useSelector((state: State) =>
    getCarPriceWithDiscounts(state, car)
  );

  const creditPrice = car?.user_metadata?.credit_price;
  const leasingPrice = car?.price?.leasing_price;

  const extraBtn = getSpecificationsForGroup(
    car?.specification,
    'car-page-extra-btn',
    ['title', 'url']
  );

  const featureFlagTestTruckButton = config.get('featureFlagTestTruckButton');
  const featureFlagCarsSeen = config.get('featureFlagCarsSeen');
  const isCarGroupsUsed = config
    .get('carGroups')
    .find((item: Record<string, any>) => item.id === 'used');

  const eventScrollToForms = new CustomEvent('scrollToForms');
  const scrollToForms = useCallback(() => {
    smoothScroll.animateScroll(document.getElementById('forms'));
    // scrollStart & scrollEnd не срабатывают если отключена анимация в OS
    document.dispatchEvent(eventScrollToForms);
  }, []);

  const formRef = useBookScrollByQueryParam(car, eventScrollToForms);

  const { dealerOffices } = useDealerOffices();

  const isCarGroupsNew = config
    .get('carGroups')
    // eslint-disable-next-line no-shadow
    .find(({ id }: { id: string }) => id === 'new');

  const carModelImage = getCarModelImage(store.getState(), car);

  useEffect(() => {
    dispatch(servicesActions.clearServices());
  }, [car]);

  return car ? (
    <CarContext.Provider value={{ carPriceInfo, carPriceWithDiscounts }}>
      <LayoutMain>
        <CarHeaderFixed car={car} btnClickHandler={scrollToForms} />
        <CarBar />
        <CarTitle title={car.name} />
        <CarCard key={car.id}>
          <div className="col-lg-7">
            <CarGallery
              images={car.images}
              imageDefault={carModelImage}
              presets={{
                gallery: {
                  id: '101',
                },
                'gallery-full': {
                  id: '102',
                },
                'gallery-thumbnail': {
                  id: '103',
                },
              }}
            />
          </div>
          <div className="col-lg-5">
            <CarInfo
              topBg
              top={
                <>
                  {config.get('featureFlagFavourites') && (
                    <FavouriteBtn className={style.btnFavourite} car={car} />
                  )}
                  <CarInfoFinance>
                    <CarInfoPrice />
                  </CarInfoFinance>
                  {/* TODO: Временно скрыто
                    <CarInfoCredit
                      creditPayment={creditPrice}
                      leasingPayment={leasingPrice}
                    />
                  */}
                  <div className="mb-5">
                    <CarInfoActions>
                      <CarPresentationLink
                        car={car}
                        dealerOffices={dealerOffices}
                      />

                      <Button onClick={scrollToForms}>Забронировать</Button>

                      {featureFlagTestTruckButton && !isCarGroupsUsed && (
                        <CarTestTruckLink
                          car={car}
                          dealerOffices={dealerOffices}
                        />
                      )}

                      {Object.keys(extraBtn).length === 2 && (
                        <Button href={extraBtn.url}>{extraBtn.title}</Button>
                      )}
                    </CarInfoActions>
                  </div>
                  <CarVinInfo car={car} />
                </>
              }
              bottom=""
            />
          </div>
        </CarCard>
        <div id="info-properties" className="azgazui__car-info-properties">
          <div className="container">
            <Title level="h2">Характеристики автомобиля</Title>
            <CarInfoProperties fields={carFields} car={car} view="col" />
          </div>
        </div>
        <ErrorBoundary fallbackRender={() => <></>}>
          <CarOptions
            userMetadata={car.user_metadata}
            title="Опции комплектации"
          />
        </ErrorBoundary>

        <div className={style.comment}>
          <CarComment specification={car.specification} />
        </div>

        {isCarGroupsNew && <CarServicesList car={car} />}
        <div ref={formRef}>
          <CarForms dealerOffices={dealerOffices} />
        </div>
        <CarsRelated />
        {featureFlagCarsSeen && <CarsSeen car={car} />}
        <CarFooter>
          <CarBackButton className="mb-5" />
          <CarDisclaimer car={car} />
        </CarFooter>
      </LayoutMain>
    </CarContext.Provider>
  ) : (
    <Loader style={{ margin: '50px auto', display: 'block' }} />
  );
};

export default Car;
