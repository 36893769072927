import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { analytics } from '../middlewares';
import discounts from './discounts';
import services from './services';
import order from './order';
import book from './book';
import carModels from './carModels';

const store = configureStore({
  reducer: {
    discounts: discounts.reducer,
    services: services.reducer,
    order: order.reducer,
    book: book.reducer,
    carModels: carModels.reducer,
  },
  middleware: [...getDefaultMiddleware(), analytics],
});

export type Store = typeof store;
export type GetState = typeof store.getState;
export type State = ReturnType<GetState>;

export default store;
