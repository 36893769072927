import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'azgaz-ui';

type TProps = {
  className?: string;
};

const BackButton: FC<TProps> = ({ className }) => {
  const history = useHistory();

  const onClick = () => history.push('/');

  return (
    <Button className={className} onClick={onClick} transparent>
      назад к списку
    </Button>
  );
};

export default BackButton;
