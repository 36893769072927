import React, { FC, ReactNode } from 'react';
import { Form, Modal } from 'antd/lib';
import { Checkbox, Field } from 'azgaz-ui';

import 'antd/lib/modal/style/index.css';
import style from './style.module.scss';

type TermCheckboxProps = {
  name: string;
  title: ReactNode;
  toogleTitle: ReactNode;
  form: any;
  onToogleClick: () => void;
  term?: ReactNode;
  termVisibility?: boolean;
  error?: ReactNode;
  rules?: any[];
  modalMode?: boolean;
};

const TermCheckbox: FC<TermCheckboxProps> = ({
  error,
  form,
  name,
  rules,
  termVisibility,
  onToogleClick,
  term,
  title,
  toogleTitle,
  modalMode,
}) => {
  const termContent = modalMode ? (
    <Modal
      visible={termVisibility}
      footer={null}
      width="90%"
      onCancel={onToogleClick}
    >
      {term}
    </Modal>
  ) : (
    <div className={style.termText}>{term}</div>
  );

  const content = (
    <>
      <button type="button" className={style.termLink} onClick={onToogleClick}>
        {toogleTitle}
      </button>
      {termVisibility && term && termContent}
    </>
  );

  if (typeof form.getFieldDecorator !== 'function') {
    return (
      <Form.Item>
        <Form.Item name={name} rules={rules} valuePropName="checked" noStyle>
          <Checkbox className={style.term}>{title}</Checkbox>
        </Form.Item>{' '}
        {content}
      </Form.Item>
    );
  }

  return (
    <Field error={error}>
      {form.getFieldDecorator(name, {
        rules,
      })(<Checkbox className={style.term}>{title}</Checkbox>)}{' '}
      {content}
    </Field>
  );
};

export default TermCheckbox;
