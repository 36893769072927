import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { numberFormat, Title, Checkbox } from 'azgaz-ui';
import {
  selectors as selectorsConnect,
  useSelector as useSelectorConnect,
} from 'azgaz-connect';
import { resolveCarDefaultCreditType } from '@/utils';
import {
  discountsActions,
  fields as discountFields,
  creditTypes,
} from '../../store/discounts';
import { getCurrentDiscounts, getCarPriceInfo } from '../../selectors';
import Popover from '../Popover';
import Radio, { Group } from '../Radio';
import { ReactComponent as IconDiscount } from '../Icons/icon-discount.svg';
import style from './style.module.scss';

const CarDiscount: FC = () => {
  const currentDiscounts = useSelector(getCurrentDiscounts);
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({ ...currentDiscounts });
  const dispatch = useDispatch();

  /* Копируем redux стейт в стейт компонента т.к. у нас есть кнопка "Применить" */
  useEffect(() => {
    if (visible) {
      setState(currentDiscounts);
    }
  }, [visible]);

  /* Сброс скидок при переходе на другую страничку */
  useEffect(
    () => () => {
      dispatch(discountsActions.reset());
    },
    []
  );

  const [open, close] = useMemo(
    () =>
      [true, false].map(value => () => {
        setVisible(value);
      }),
    []
  );

  const checkboxHandler = useCallback(
    event => {
      const newState = {
        ...state,
        [event.target.name]: event.target.checked,
      };
      setState(newState);
      dispatch(discountsActions.setDiscounts(newState));
    },
    [state]
  );

  const groupChangeHandler = useCallback(
    event => {
      const newState = {
        ...state,
        [event.target.name]: event.target.value,
      };
      setState(newState);
      dispatch(discountsActions.setDiscounts(newState));
    },
    [state]
  );

  const car = useSelectorConnect(selectorsConnect.getCar);
  const carPriceInfo = getCarPriceInfo(car);

  /*
   * Устанавливаем тип кредита лизинг, как дефолтный,
   * если обычный кредит не поддерживается данным авто
   */
  useEffect(() => {
    const defaultCreditType = resolveCarDefaultCreditType(car);

    if (defaultCreditType !== creditTypes.creditDiscount) {
      dispatch(
        discountsActions.setDiscounts({
          [discountFields.creditType]: creditTypes.leasingDiscount,
        })
      );
    }
  }, [car]);

  const isCarHasSomeEditableDiscounts = Boolean(
    carPriceInfo.tradeIn || carPriceInfo.credit || carPriceInfo.leasing
  );

  const isCarHasSomeDiscounts = isCarHasSomeEditableDiscounts;

  return car && isCarHasSomeDiscounts ? (
    <span className={style.calcDiscount}>
      <Popover
        placement="bottom"
        visible={visible}
        trigger="click"
        onVisibleChange={setVisible}
        title={
          <div className={style.header}>
            <Title level="h3">Перечень возможных выгод</Title>{' '}
            <button type="button" onClick={close} className={style.closeButton}>
              &#215;
            </button>
          </div>
        }
        content={
          <div className={style.content}>
            {Boolean(carPriceInfo.tradeIn) && (
              <div
                className={cn(style.discount, {
                  [style.discountActive]: state.tradeIn,
                })}
              >
                <div className={style.discountName}>
                  Выгода при сдаче автомобиля в Trade-in
                  <sup>{carPriceInfo.disclaimers.order.indexOf('tradeIn')}</sup>
                </div>
                <div className={style.discountValue}>
                  {`-${numberFormat()(carPriceInfo.tradeIn)} ₽`}
                </div>
                <Checkbox
                  name={discountFields.tradeIn}
                  className={style.checkbox}
                  value={state.tradeIn}
                  onChange={checkboxHandler}
                />
              </div>
            )}
            {Boolean(carPriceInfo.credit || carPriceInfo.leasing) && (
              <div
                className={cn(style.discount, {
                  [style.discountActive]: state.credit,
                })}
              >
                <div className={style.discountName}>
                  Выгода при покупке в кредит/лизинг
                </div>
                <Group
                  onChange={groupChangeHandler}
                  value={state.creditType}
                  className={style.creditType}
                  name={discountFields.creditType}
                >
                  {Boolean(carPriceInfo.credit) && (
                    <Radio value={creditTypes.creditDiscount}>
                      Кредит
                      <sup>
                        {carPriceInfo.disclaimers.order.indexOf('credit')}
                      </sup>
                    </Radio>
                  )}
                  {Boolean(carPriceInfo.leasing) && (
                    <Radio value={creditTypes.leasingDiscount}>
                      Лизинг
                      <sup>
                        {carPriceInfo.disclaimers.order.indexOf('leasing')}
                      </sup>
                    </Radio>
                  )}
                </Group>
                <div className={style.discountValue}>
                  {`-${numberFormat()(
                    state.creditType === creditTypes.creditDiscount &&
                      carPriceInfo.credit
                      ? carPriceInfo.credit
                      : carPriceInfo.leasing
                  )} ₽`}
                </div>
                <Checkbox
                  className={style.checkbox}
                  name={discountFields.credit}
                  value={state.credit}
                  onChange={checkboxHandler}
                />
              </div>
            )}
          </div>
        }
      >
        <button type="button" className={style.openButton} onClick={open}>
          <IconDiscount className={style.percent} /> Перечень возможных выгод
        </button>
      </Popover>
    </span>
  ) : null;
};

export default CarDiscount;
