import { createSelector } from '@reduxjs/toolkit';
import { fieldsToKeyValue } from '@/utils';
import { selectors as selectorsConnect, CarstockState } from 'azgaz-connect';
import {
  getSpecification,
  getSpecificationsForGroup,
  numberFormat,
} from 'azgaz-ui';
import { TFields } from '../store/book';
import { State } from '../store';
import getBookFields from './getBookFields';
import getCarPriceWithDiscounts from './getCarPriceWithDiscounts';
import getCarPriceInfo from './getCarPriceInfo';
import getSelectedServices from './getSelectedServices';

/**
 * Данные по броне для отправки в сервис форм
 */
const getBookFsData = createSelector(
  getBookFields,
  (state: State, carstockState: CarstockState) => {
    const car = selectorsConnect.getCar(carstockState);
    const carPriceWithDiscounts = getCarPriceWithDiscounts(state, car);
    const carPriceInfo = getCarPriceInfo(car);
    const selectedServices = getSelectedServices(state);

    const dealerComment =
      car && getSpecification(car.specification, 'other', 'dealer_comment');

    const mainSpecification = getSpecificationsForGroup(
      car?.specification,
      'main',
      ['modification', 'drive_type', 'engine_type', 'passangers', 'use']
    );

    return (
      car && {
        dealerComment,
        carPriceWithDiscounts: numberFormat()(carPriceWithDiscounts),
        carModification: mainSpecification.modification?.toString(),
        carDriveType: mainSpecification.drive_type,
        carEngineType: mainSpecification.engine_type,
        carPassangers: mainSpecification.passangers?.toString(),
        carUse: mainSpecification.use?.toString(),
        carModel: car.grade,
        carVin: car.vin,
        carName: car.model,
        carYear: car.year.toString(),
        carPrice: numberFormat()(carPriceInfo.price),
        services: selectedServices.join(','),
      }
    );
  },
  (fields, carInfo) => {
    const resolvedFormFields = fieldsToKeyValue<
      { [K in keyof TFields]: TFields[K]['value'] }
    >(fields);

    const fullData = { ...resolvedFormFields, ...carInfo };
    return fullData;
  }
);

export default getBookFsData;
