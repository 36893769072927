/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State } from '../index';

type TServices = {
  [K: string]: boolean;
};

type TSlicesServices = {
  services: TServices;
};

const initialState: TSlicesServices = {
  services: {},
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    toggleService(state, action: PayloadAction<TServices>) {
      state.services = {
        ...state.services,
        ...action.payload,
      };
    },
    clearServices(state) {
      state.services = {};
    },
  },
});

export default servicesSlice;

export const servicesActions = servicesSlice.actions;

export const servicesSelectors = {
  selectServices: (state: State) => state.services,
};
