import { FC, Component } from 'react';
import { createForm, createFormField } from 'rc-form';

export type TFields = { [key: string]: { value: any } };

const connectForm = (
  formStatePropName: string,
  updateFieldsAction: (fields: TFields) => any,
  component: FC<any> | Component<any>
) => {
  return createForm({
    mapPropsToFields(props: any) {
      const formState: TFields = props[formStatePropName];

      return Object.keys(formState).reduce((acc, fieldName) => {
        acc[fieldName] = createFormField(formState[fieldName]);
        return acc;
      }, {} as any);
    },
    onFieldsChange(props: any, fields: TFields) {
      props.dispatch(updateFieldsAction(fields));
    },
  })(component);
};

export default connectForm;
