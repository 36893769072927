import { Car } from 'azgaz-connect';
import { createSelector } from '@reduxjs/toolkit';
import { getSpecification } from 'azgaz-ui';
import { carFields, generateOrderNum } from '@/utils';
import { State } from '../store';
import getOrderFields from './getOrderFields';
import getDealerRequisites from './getDealerRequisites';
import getCarPriceInfo from './getCarPriceInfo';

export default createSelector(
  getOrderFields,
  (state: State, car: Car | undefined) => {
    const dealerRequisites = getDealerRequisites(state, car);
    const { prepayment } = car?.price;

    const resolvedDealerRequisites = dealerRequisites
      ? { ...dealerRequisites }
      : undefined;

    // перезаписываем размер предоплаты, если у самого авто указан prepayment
    if (resolvedDealerRequisites && prepayment) {
      resolvedDealerRequisites.prepayment = prepayment;
    }

    delete resolvedDealerRequisites?.contract_offer; // Убираем договор т.к с ним вылазием за рамки максимальной длины URLQueryString
    delete resolvedDealerRequisites?.contract_offer_personal;
    return resolvedDealerRequisites;
  },
  (state: State, car: Car | undefined) => {
    type PriceInfoKeys =
      | 'carPriceInfoNds'
      | 'carPriceInfoGazHelp'
      | 'carPriceInfoTradeIn'
      | 'carPriceInfoCredit'
      | 'carPriceInfoLeasing'
      | 'carPriceInfoPrice'
      | 'carPriceInfoSpecPrice';

    const carPriceInfo = Object.entries(getCarPriceInfo(car)).reduce(
      (acc, [name, value]) => {
        if (name === 'disclaimers') {
          return acc;
        }
        const resolveName = name[0].toUpperCase() + name.slice(1);
        const key = `carPriceInfo${resolveName}` as PriceInfoKeys;
        acc[key] = value;
        return acc;
      },
      {} as { [key in PriceInfoKeys]: number }
    );

    const date = new Date();
    const specifications: string[] = [];
    carFields.forEach(field => {
      let value: any = '';
      let measure = '';
      switch (field.source) {
        case 'specification':
          value = getSpecification(car?.specification, 'main', field.id);
          measure =
            field.measure ||
            getSpecification(car?.specification, 'main', field.id, 'measure');
          break;
        case 'root':
          value = car ? car[field.id] : '';
          measure = field.measure || '';
          break;
        default:
          break;
      }
      if (value) {
        const resolvedValue =
          typeof field.get === 'function' ? field.get(value) : value;
        specifications.push(`${resolvedValue}${measure ? ` ${measure}` : ''}`);
      }
    });

    const formFields = getOrderFields(state);
    const orderNumGenerated = generateOrderNum(formFields, {
      exclude: ['termGettingInfo', 'termPersonalData', 'termOrderAgreement'],
    });

    return {
      family: car ? `${car?.model} ${car?.grade}` : undefined,
      vin: car?.vin,
      specifications,
      orderDate: [
        String(date.getDate()).padStart(2, '0'),
        String(date.getMonth() + 1).padStart(2, '0'),
        date.getFullYear().toString(),
      ],
      orderNum: `ГАЗ${orderNumGenerated}`,
      ...carPriceInfo,
    };
  },
  (formFields, requisites, additional) => {
    if (!requisites) return;

    // eslint-disable-next-line consistent-return
    return {
      ...formFields,
      ...requisites,
      ...additional,
    };
  }
);
