import { createSelector } from '@reduxjs/toolkit';

import { Car } from 'azgaz-connect';
import getCarModels from './getCarModels';
import { State } from '../store';

const getCarModelImage = createSelector(
  getCarModels,
  (state: State, car: Car | undefined) => car?.parent_id[0],
  ({ models }, parentId) => {
    const carModel = models.find(item => item.id === parentId);

    if (carModel?.images) {
      return carModel.images[0];
    }

    return undefined;
  }
);

export default getCarModelImage;
