import { useEffect, useState, useCallback } from 'react';
import SmoothScroll from 'smooth-scroll';

const smoothScroll = new SmoothScroll(undefined, {
  header: '.azgazui__car-header-fixed',
  updateURL: false,
});

export default function useOfferCollapse() {
  const [offersCollapse, setOffersCollapse] = useState<string | string[]>([]);
  const contractOfferCollapseId = 'contract-offer';

  // Автоматическое открытие договора оферты при проскроливании
  useEffect(() => {
    const scrollToFormsHandler = (e: any) => {
      if (e.type === 'scrollToOffer') {
        setOffersCollapse(['0']);
      }
    };

    document.addEventListener('scrollToOffer', scrollToFormsHandler, false);

    return () => {
      document.removeEventListener(
        'scrollToOffer',
        scrollToFormsHandler,
        false
      );
    };
  }, []);

  const eventScrollToOffer = new CustomEvent('scrollToOffer');
  const scrollToOffer = useCallback(() => {
    smoothScroll.animateScroll(
      document.getElementById(contractOfferCollapseId)
    );
    // scrollStart & scrollEnd не срабатывают если отключена анимация в OS
    document.dispatchEvent(eventScrollToOffer);
  }, []);

  return {
    contractOfferCollapseId,
    offersCollapse,
    setOffersCollapse,
    scrollToOffer,
  };
}
