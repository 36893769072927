import React, { FC, useEffect } from 'react';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';
import style from './style.module.scss';

const ScrollBar: FC<ScrollBarProps> = ({ children, ...rest }) => {
  const scrollDivRef = React.createRef<any>();

  /**
   * Для обновления высоты scroll,
   * чтобы scrollbar видим сразу
   */
  useEffect(() => {
    scrollDivRef.current?.updateScroll();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PerfectScrollbar ref={scrollDivRef} className={style.scrollbar} {...rest}>
      {children}
    </PerfectScrollbar>
  );
};

export default ScrollBar;
