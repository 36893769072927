import renderColorOption from './renderColorOption';

export default [
  {
    source: 'specification',
    id: 'modification',
    title: 'Модификация',
  },
  {
    source: 'specification',
    id: 'use',
  },
  {
    source: 'specification',
    id: 'passangers',
    title: 'Пассажировместимость',
  },
  {
    source: 'specification',
    id: 'engine_type',
  },
  {
    source: 'specification',
    id: 'drive_type',
  },
  {
    source: 'specification',
    id: 'load_capacity',
  },
  {
    source: 'specification',
    id: 'cargo_volume',
  },
  {
    source: 'specification',
    id: 'weight',
  },
  {
    source: 'specification',
    id: 'chassis_length',
  },
  {
    source: 'root',
    id: 'mileage',
    title: 'Пробег, км',
    measure: 'км',
  },
  {
    source: 'root',
    id: 'year',
    title: 'Год выпуска',
  },
  {
    source: 'root',
    id: 'color',
    title: 'Цвет',
    render: renderColorOption,
    get: (value: Record<string, any>) => value.name,
  },
];
