import declOfNum from './declOfNum';

type TSpecifications = {
  [key: string]: any;
};

const getCarDescription = (specifications: TSpecifications) => {
  const passangers =
    specifications.passangers &&
    `${specifications.passangers} ${declOfNum(specifications.passangers, [
      'место',
      'места',
      'мест',
    ])}`;

  const fields = [specifications.chassis_length, passangers];
  const descr = fields.filter(x => x).join(', ');

  return descr;
};

export default getCarDescription;
