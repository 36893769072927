/* eslint-disable global-require, import/prefer-default-export, import/no-dynamic-require */
import React from 'react';
import { render } from 'react-dom';
import { validateConfig, config } from '@/utils';

const defaultConfig = require(`./apps/${ENV.widget}/defaultConfig`).default;

// Hot module replacement
if (module.hot) {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  process.env.NODE_ENV === 'development' && module.hot.accept();
}

export function create(initConfig = {}) {
  config.set({
    selectorId: 'carstock-widget',
    ...defaultConfig,
    ...initConfig,
  });

  validateConfig(config.all(), config.get('validateConfig'));

  const App = require(`./apps/${ENV.widget}/index`).default;

  render(<App />, document.getElementById(config.get('selectorId')));
}
