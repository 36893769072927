import React, { FC, useEffect } from 'react';
import 'azgaz-ui/style.css';
import '../../../../assets/sass/main.scss';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, import/extensions
import antdStyles from '!style-loader?injectType=lazySingletonStyleTag!css-loader!antd/lib/style/index.css'; // Для работы popover нужен глобальнй файл стилей antd, но эти тили конфликтуют со стилями /order

const LayoutMain: FC = ({ children }) => {
  useEffect(() => {
    antdStyles.use();
    return () => antdStyles.unuse();
  });

  return <div className="csp-widget">{children}</div>;
};

export default LayoutMain;
