import React, { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import {
  useSelector as useSelectorConnect,
  selectors as selectorsConnect,
} from 'azgaz-connect';
import style from './style.module.scss';
import { getDealerRequisites } from '../../selectors';
import { State } from '../../store';

const CarOrderHint: FC = () => {
  const car = useSelectorConnect(selectorsConnect.getCar);
  const { prepayment } = car?.price;
  const dealerRequisites = useSelector((state: State) =>
    getDealerRequisites(state, car)
  );

  return (
    <div className={cn(style.hint, 'mt-4', 'mt-md-0')}>
      Вы можете внести обеспечительный платеж за автомобиль в размере{' '}
      {prepayment || dealerRequisites?.prepayment} руб. Оформите счёт,
      воспользовавшись формой ниже
    </div>
  );
};

export default CarOrderHint;
