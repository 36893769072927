import React, { FC, useState, MouseEvent } from 'react';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import {
  actions as actionsConnect,
  useDispatch as useDispatchConnect,
  Car,
} from 'azgaz-connect';
import { useFavoritesTitle } from '@/hooks';

import FavouritesPopover from '../FavouritesPopover';

import style from './style.module.scss';

type CarsItemFavouriteBtnProps = {
  car: Car;
  className?: string;
};

const CarsItemFavouriteBtn: FC<CarsItemFavouriteBtnProps> = ({
  car,
  className,
}) => {
  const [visiblePopover, setVisiblePopover] = useState(false);
  const dispatchConnect = useDispatchConnect();
  const { favouritesPopoverTxt, favouritesEntities } = useFavoritesTitle(car);
  const timeoutHide = isMobile ? 400 : 0;

  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  const addToFavourites = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatchConnect(actionsConnect.favourites.switchFavourite(car));
  };

  const handleVisibleChange = (visible: boolean) => {
    const timeout = visible ? 0 : timeoutHide;

    setTimeout(() => {
      setVisiblePopover(visible);
    }, timeout);
  };

  return (
    <FavouritesPopover
      overlayClassName={cn({
        [style.popoverActive]: favouritesEntities[car.id],
      })}
      trigger={isMobile ? 'click' : 'hover'}
      content={favouritesPopoverTxt}
      visible={visiblePopover}
      onVisibleChange={handleVisibleChange}
    >
      <button
        type="button"
        className={cn(style.switch, className, {
          [style.active]: favouritesEntities[car.id],
        })}
        onClick={addToFavourites}
      >
        <span className={style.icon} />
      </button>
    </FavouritesPopover>
  );
};

export default CarsItemFavouriteBtn;
