import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useCollapses } from '@/hooks';

import { Car } from 'azgaz-connect';
import {
  getSelectedServices,
  getCarServices,
} from '@/apps/appCreator/selectors';
import CarService from '../CarService';

import style from './style.module.scss';
import styleCarForms from '../CarForms/style.module.scss';

type TProps = {
  car: Car | undefined;
};

const CarServicesList: FC<TProps> = ({ car }) => {
  const [termCollapse] = useCollapses([true]);
  const selectedServices = useSelector(getSelectedServices);
  const countSelectedServices = selectedServices.length;
  const services = getCarServices(car);
  const isServicesExists = services.length > 0;

  return isServicesExists ? (
    <div className={cn('azgazui__services', style.wrapper)}>
      <div className="container">
        <div>
          <div className="row justify-content-between">
            <div className="col-auto">
              <h2 className="azgazui__title-h2">
                Доступные спецпредложения и сервисы{' '}
                <span className={style.servicesChoosen}>
                  выбрано{' '}
                  <span className={style.currCount}>
                    {countSelectedServices}
                  </span>{' '}
                  из <span className={style.allCount}>{services.length}</span>
                </span>
              </h2>
            </div>
            <div className="col-auto">
              <button
                type="button"
                className={cn(styleCarForms.btnToggle, {
                  open: termCollapse.visibility,
                })}
                onClick={termCollapse.toggle}
              >
                {termCollapse.visibility ? 'свернуть' : 'развернуть'}
              </button>
            </div>
          </div>
        </div>
        <div
          className={cn(style.tabContents, {
            show: termCollapse.visibility,
          })}
        >
          <div className="row">
            {services.map(service => (
              <CarService
                service={service}
                key={service.name}
                className="col-md-6 col-lg-4 d-md-flex"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CarServicesList;
