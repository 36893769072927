import React, { FC } from 'react';

import style from './style.module.scss';

type Props = {
  isOpen?: boolean;
};

const CollapseCaretRight: FC<Props> = ({ isOpen }) => {
  return (
    <div className={cn(style.caret, { [style.isOpen]: isOpen })}>
      {isOpen ? 'свернуть' : 'развернуть'}
    </div>
  );
};

export default CollapseCaretRight;
