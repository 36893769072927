/* eslint-disable @typescript-eslint/camelcase */
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Title, Checkbox } from 'azgaz-ui';

import {
  servicesActions,
  servicesSelectors,
} from '@/apps/appCreator/store/services';
import { TService } from '@/apps/appCreator/selectors/getCarServices';
import { useCollapses } from '@/hooks';
import Popover from '../Popover';

import style from './style.module.scss';

type TProps = {
  service: TService;
  className?: string;
};

const CarService: FC<TProps> = ({ className, service }) => {
  const dispatch = useDispatch();
  const services = useSelector(servicesSelectors.selectServices);
  const { name, descr, full_descr, price } = service;
  const selectedService = services.services[name];
  const [termServiceCollapse] = useCollapses([false]);

  const toggleSelectService = () => {
    dispatch(servicesActions.toggleService({ [name]: !selectedService }));
  };

  const bottomContent = (
    <div>
      {price && (
        <div className={style.textLg}>
          {price} <span className={style.txtRuble}>₽</span>
        </div>
      )}
    </div>
  );

  const fullDescrContent = (
    <>
      <div className={style.fullDescr}>{full_descr}</div>
      {bottomContent}
    </>
  );

  return (
    <div className={className}>
      <div
        className={cn(style.service, {
          [style.serviceSelected]: selectedService,
        })}
      >
        <div className={style.serviceTop}>
          <h3 className={style.title}>{name}</h3>
          <Checkbox
            value={selectedService}
            className={style.checkbox}
            onChange={toggleSelectService}
          />
        </div>

        <div className={style.serviceBody}>{descr}</div>

        <div className={style.serviceBottom}>
          {bottomContent}

          {full_descr && (
            <Popover
              title={
                <div className={style.popoverHeader}>
                  <Title level="h4">{name}</Title>{' '}
                  <button
                    className={style.btnClose}
                    type="button"
                    onClick={termServiceCollapse.hide}
                  >
                    &#215;
                  </button>
                </div>
              }
              visible={termServiceCollapse.visibility}
              onVisibleChange={termServiceCollapse.toggle}
              placement="bottom"
              trigger="click"
              content={<div>{fullDescrContent}</div>}
            >
              <button type="button" className={style.btnPopover}>
                Подробно
              </button>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarService;
