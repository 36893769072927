import { Middleware } from 'redux';
// import {
//   getStore as getStoreConnect,
//   selectors as selectorsConnet,
// } from 'azgaz-connect';
// import { config } from '@/utils';
// import { orderActions } from '../store/order';
// import { getOrderFullInfo } from '../selectors';

const analytics: Middleware = store => next => action => {
  next(action);

  // Отправка формы "Получить счет"
  // if (orderActions.fsRequest.match(action)) {
  //   const callback = config.get('formOnSubmitOrder');

  //   if (typeof callback === 'function') {
  //     const orderFullInfo = getOrderFullInfo(
  //       store.getState(),
  //       selectorsConnet.getCar(getStoreConnect().getState())
  //     );

  //     callback(orderFullInfo);
  //   }
  // }
};

export default analytics;
