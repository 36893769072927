import React, { FC } from 'react';
import { Title, CarsBarSort } from 'azgaz-ui';
import * as connect from 'azgaz-connect';
import CarsBarFavourites from '../CarsBarFavourites';
import CarBackButton from '../CarBackButton';
import style from './style.module.scss';

type FavouritesTitleProps = {};

const FavouritesTitle: FC<FavouritesTitleProps> = () => {
  const dispatchConnect = connect.useDispatch();

  const fetchFavouriteCarsState = connect.useSelector(
    connect.selectors.favourites.fetchFavouriteCars
  );

  return (
    <>
      <div className={style.back}>
        <CarBackButton />
      </div>
      <div className={style.title}>
        <Title level="h1">Избранные автомобили</Title>
      </div>
      <div className={style.head}>
        <div className={style.favourites}>
          <CarsBarFavourites asLink={false} />
        </div>
        <div className={style.sort}>
          <CarsBarSort
            current={fetchFavouriteCarsState.sort || '-state_active_at'}
            setSort={value =>
              dispatchConnect(
                connect.actions.favourites.setSortAndFetchCars(value)
              )
            }
            options={[
              { id: 'price', label: 'Цене' },
              { id: 'state_active_at', label: 'Дате добавления' },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default FavouritesTitle;
