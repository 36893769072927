import React, { FC, useEffect } from 'react';
import LayoutMain from '../LayoutMain';
import FavouritesList from '../FavouritesList';
import FavouritesTitle from '../FavouritesTitle';
import style from './style.module.scss';

type FavouritesProps = {};

const Favourites: FC<FavouritesProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutMain>
      <div className={style.favourites}>
        <div className="container">
          <FavouritesTitle />
          <FavouritesList />
        </div>
      </div>
    </LayoutMain>
  );
};

export default Favourites;
