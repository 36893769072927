import React, { FC, useEffect } from 'react';
import { useTabs, useCollapses } from '@/hooks';
import { TDealerOffice } from '@/types';
import {
  useSelector as useSelectorConnect,
  selectors as selectorsConnect,
} from 'azgaz-connect';
import { getDealerOfficesActiveRequisites } from '../../selectors';
import CarOrder from '../CarOrder';
import CarBook from '../CarBook';
import style from './style.module.scss';

type CarFormsProps = {
  dealerOffices: TDealerOffice[];
};

const CarForms: FC<CarFormsProps> = ({ dealerOffices }) => {
  const car = useSelectorConnect(selectorsConnect.getCar);
  const officesWithActiveRequisites = getDealerOfficesActiveRequisites(car);
  const [termFormCollapse] = useCollapses([false]);

  const openFormCollapse = () => termFormCollapse.setVisibility(true);

  const { renderTabs, renderContents, setCurrentTab } = useTabs(
    {
      initTabIdx: 0,
    },
    openFormCollapse
  );

  const titles: React.ReactNode[] = [
    <span className={style.bookTabTitle}>Забронировать</span>,
  ];
  const contents = [<CarBook dealerOffices={dealerOffices} />];

  // Вкладку "Получить счет" показываем только если включна соответствующая опция
  const visibilityCarOrderForm = officesWithActiveRequisites?.length;

  if (visibilityCarOrderForm) {
    titles.unshift('Получить счет');
    contents.unshift(<CarOrder dealerOffices={dealerOffices} />);
  }

  // Автоматическое открытие вкладки "Забронировать"
  useEffect(() => {
    const scrollToFormsHandler = (e: any) => {
      if (e.type === 'scrollToForms') {
        // показываем формы, по умолчанию формы скрыты
        termFormCollapse.setVisibility(true);
        // Открываем вкладку "Забронировать" только если отображается "Получить счет"
        if (visibilityCarOrderForm) {
          setCurrentTab(1);
        }
      }
    };

    document.addEventListener('scrollToForms', scrollToFormsHandler, false);

    return () => {
      document.removeEventListener('scrollToForms', scrollToFormsHandler);
    };
  }, []);

  return (
    <div className="azgazui__forms" id="forms">
      <div className="container">
        <div className={style.tabTitles}>
          <div className="row justify-content-between">
            <div className="col-md-7">{renderTabs(titles)}</div>
            <div className="col-auto">
              <button
                type="button"
                className={cn(style.btnToggle, {
                  open: termFormCollapse.visibility,
                })}
                onClick={termFormCollapse.toggle}
              >
                {termFormCollapse.visibility ? 'свернуть' : 'развернуть'}
              </button>
            </div>
          </div>
        </div>
        <div
          className={cn(style.tabContents, {
            show: termFormCollapse.visibility,
          })}
        >
          {renderContents(contents)}
        </div>
      </div>
    </div>
  );
};

export default CarForms;
