import { connect } from 'react-redux';
import { connectForm } from '@/utils';
import { State } from '../../store';
import { orderActions } from '../../store/order';
import CarOrder from './CarOrder';

export default connect((state: State) => {
  return {
    formState: state.order.fields,
  };
})(connectForm('formState', orderActions.setFields, CarOrder));
