import { connect } from 'react-redux';
import { connectForm } from '@/utils';
import CarBook from './CarBook';
import { State } from '../../store';
import { bookActions } from '../../store/book';

export default connect((state: State) => {
  return {
    formState: state.book.fields,
  };
})(connectForm('formState', bookActions.setFields, CarBook));
