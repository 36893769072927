import React, { FC } from 'react';
import cn from 'classnames';
import AntCollapse, { CollapseProps } from 'antd/lib/collapse';

import CollapseCaretRight from '../CollapseCaretRight';

import 'antd/lib/collapse/style/index.css';
import style from './style.module.scss';

const Collapse: FC<CollapseProps> = ({ className, ...restProps }) => {
  return (
    <AntCollapse
      className={cn(style.collapse, className)}
      expandIcon={({ isActive }) => <CollapseCaretRight isOpen={isActive} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    />
  );
};

export default Collapse;
export const { Panel } = AntCollapse;
