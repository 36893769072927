/* eslint-disable no-nested-ternary */
import React, { FC, useEffect } from 'react';
import * as connect from 'azgaz-connect';
import { Loader } from 'azgaz-ui';
import CarsItem from '../CarsItem';
import style from './style.module.scss';

type FavouritesListProps = {};

const FavouritesList: FC<FavouritesListProps> = () => {
  const favorites = connect.useSelector(
    connect.selectors.favourites.favouritesAll
  );

  const fetchFavouriteCarsState = connect.useSelector(
    connect.selectors.favourites.fetchFavouriteCars
  );

  const favoritesCars = connect.useSelector(
    connect.selectors.favourites.carsAll
  );

  const dispatchConnect = connect.useDispatch();

  useEffect(() => {
    dispatchConnect(connect.actions.favourites.fetchFavouriteCars());
  }, [favorites]);

  return (
    <div className="row">
      {favoritesCars.length ? (
        favoritesCars.map(car => <CarsItem car={car} key={car.id} />)
      ) : fetchFavouriteCarsState.loading ? (
        <Loader className={style.loader} />
      ) : (
        <div className={cn('col', style.empty)}>Нет избранных автомобилей</div>
      )}
    </div>
  );
};

export default FavouritesList;
