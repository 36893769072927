/* eslint-disable @typescript-eslint/camelcase */
import { Car } from 'azgaz-connect';

/**
 * В блоке "Вас могут заинтересовать" должны выводиться похожие автомобили по критериям ниже:
1) для ГАЗель Next Борт выводим автомобили ГАЗель Next Борт (обязательно должны выводиться автомобили 3,5 и 4,6 тонн). Диапазона по цене нет
2) для ГАЗель Next ЦМФ выводим автомобили ГАЗель Next ЦМФ (обязательно должны выводиться автомобили 3,5 и 4,6 тонн). Диапазона по цене нет
3) для ГАЗель City выводим только ГАЗель City. Диапазона по цене нет
4) для ГАЗель Next Автобус выводим все автобусы ГАЗель Next. Диапазона по цене нет
5) для ГАЗон Next Борт выводим автомобили ГАЗон Next и Садко Next Борт. Диапазон по цене +- 100к
6) для Соболь и ГАЗель Бизнес выводим аналогичные модели ГАЗель Next с таким же типом приводом  (для борта - борт, для цмф - цмф, для автобусов - автобусы). Диапазон по цене - нужно выводить самые бюджетные модификации, если это возможно.
7) для Садко Next Борт выводим Садко и ГАЗоны Борт. Диапазон по цене +- 100к
8) для семейства Земляк выводить автомобили Садко и Газон
 */

export default (car: Car) => {
  const { grade, model, price, drive_type } = car;
  const coveragePrice = 100000;
  const rangePrice = `[${price.value[0][0] - coveragePrice}, ${price
    .value[0][0] + coveragePrice}]`;

  // 1,2
  if (model === 'ГАЗель NEXT' && ['ЦМФ', 'борт'].includes(grade)) {
    return { model, 'specification.weight__in': `[${3500}, ${4600}]` };
  }
  // 5
  if (model === 'ГАЗон NEXT' && grade === 'борт') {
    return {
      // оборачиваем в строку чтобы корректно отрабатывал запрос к api
      model__in: `["${`${model}`}", "Садко NEXT"]`,
      'price.value.0.0': rangePrice,
    };
  }
  // 6
  if (['Соболь Бизнес', 'ГАЗель Бизнес'].includes(model)) {
    return { drive_type, model: 'ГАЗель NEXT', sort: 'price' };
  }
  // 7
  if (car.model === 'Садко NEXT' && grade === 'борт') {
    return {
      model__in: `["${`${model}`}", "ГАЗон NEXT"]`,
      'price.value.0.0': rangePrice,
    };
  }
  // 8
  if (car.model === 'Земляк') {
    return {
      model__in: '["ГАЗон NEXT", "Садко NEXT"]',
    };
  }

  // 3, 4
  return { model };
};
