/**
 * В случае смены раздела в админке (изменяется url), сюда необходимо добавить новый корректный pathname
 */
const availableBaseNames = ['/stock/new', '/stock/used', '/customers/used', '/customers/new'];

const dynamicBaseName = () => {
  let baseName = '/';

  availableBaseNames.forEach(path => {
    const index = window.location.pathname.indexOf(path);
    if (index === -1) return;
    baseName = window.location.pathname.substring(0, index + path.length);
  });

  return baseName;
};

export default dynamicBaseName;
