import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Option, Input, Textarea } from 'azgaz-ui';
import {
  useSelector as useSelectorConnect,
  selectors as selectorsConnect,
} from 'azgaz-connect';
import cn from 'classnames';
import { useFormHandlers, useCollapses } from '@/hooks';
import { rules, terms } from '@/utils';
import type { TDealerOffice } from '@/types';
import { bookActions, fields, userTypes } from '../../store/book';
import { getBook } from '../../selectors';
import InputPhone from '../InputPhone';
import TermCheckbox from '../TermCheckbox';
import Radio, { Group } from '../Radio';
import Message from '../Message';
import ButtonSubmit from '../ButtonSubmit';
import style from './style.module.scss';

type CarBookProps = {
  form: any;
  dealerOffices: TDealerOffice[];
};

const CarBook: FC<CarBookProps> = ({ form, dealerOffices }) => {
  const car = useSelectorConnect(selectorsConnect.getCar);
  const { getFieldDecorator, getFieldValue } = form;
  const dispatch = useDispatch();
  const book = useSelector(getBook);
  const userType = getFieldValue(fields.userType);

  const { onSubmit, getError } = useFormHandlers({
    form,
    submitAction: () => dispatch(bookActions.submitBook()),
  });

  const [termPersonalDataCollapse, termGettingInfoCollapse] = useCollapses([
    false,
    false,
  ]);

  // смена юр/физ лицо
  useEffect(() => {
    dispatch(bookActions.clearFormMessage());
  }, [userType]);

  return (
    <form onSubmit={onSubmit} noValidate data-car-vin={car?.vin}>
      <div style={{ marginBottom: 30 }}>
        {getFieldDecorator(fields.userType)(
          <Group>
            <Radio value={userTypes.company}>Юридическое лицо / ИП</Radio>
            <Radio value={userTypes.personal}>Физическое лицо</Radio>
          </Group>
        )}
      </div>
      <div className={style.title}>Контактная информация</div>
      <div className="row mb-4">
        <div className="col-md-4 mb-4">
          {getFieldDecorator(fields.userSurname, {
            rules: [rules.required],
          })(
            <Input
              title="Фамилия*"
              placeholder="Введите фамилию"
              error={getError(fields.userSurname)}
            />
          )}
        </div>
        <div className="col-md-4 mb-4">
          {getFieldDecorator(fields.userName, {
            rules: [rules.required],
          })(
            <Input
              title="Имя*"
              placeholder="Введите имя"
              error={getError(fields.userName)}
            />
          )}
        </div>
        <div className="col-md-4 mb-4">
          {getFieldDecorator(fields.userPhone, {
            rules: [rules.required, rules.phone],
          })(
            <InputPhone title="Телефон*" error={getError(fields.userPhone)} />
          )}
        </div>
        <div className="col-md-4 mb-4">
          {getFieldDecorator(fields.userEmail, {
            rules: [rules.email],
          })(
            <Input
              title="E-mail"
              type="email"
              placeholder="name@domain.com"
              error={getError(fields.userEmail)}
            />
          )}
        </div>
        {getFieldValue(fields.userType) === userTypes.company && (
          <div className="col-12 col-md-8 mb-4">
            {getFieldDecorator(fields.companyName, {
              rules: [rules.required],
            })(
              <Input
                title="Название организации*"
                placeholder="Введите название"
                error={getError(fields.companyName)}
              />
            )}
          </div>
        )}
        {dealerOffices?.length ? (
          <div className="col-12 mb-4">
            {getFieldDecorator(fields.dealerOffice, {
              rules: [rules.required],
            })(
              <Select
                title="Выбор дилерского центра*"
                optionLabelProp="children"
                error={getError(fields.dealerOffice)}
              >
                {dealerOffices.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
        ) : null}
        <div className="col-12">
          {getFieldDecorator(fields.userComment)(
            <Textarea title="Комментарий" />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-4">
          <TermCheckbox
            error={getError(fields.termPersonalData)}
            form={form}
            name={fields.termPersonalData}
            onToogleClick={termPersonalDataCollapse.toggle}
            termVisibility={termPersonalDataCollapse.visibility}
            title="Согласие на"
            toogleTitle="обработку персональных данных*"
            rules={[rules.term]}
            term={terms.personalData}
          />
        </div>
        <div className="col-md-4 mb-4">
          <TermCheckbox
            form={form}
            name={fields.termGettingInfo}
            onToogleClick={termGettingInfoCollapse.toggle}
            termVisibility={termGettingInfoCollapse.visibility}
            title="Согласие на"
            toogleTitle="получение информации"
            term={terms.gettingInfo}
          />
        </div>
        {book.formSuccessfullySent && (
          <div className={cn(style.success, 'col-12', 'mb-4')}>
            Заявка успешно отправлена!
          </div>
        )}
        <div className="col-12">
          <ButtonSubmit
            text='Забронировать'
            loading={book.loading.fs}
          />
        </div>
        {book.error.fs && (
          <div className="col-12 mt-4">
            <Message type="error">{book.error.fs}</Message>
          </div>
        )}
      </div>
    </form>
  );
};

export default CarBook;
