/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { DealerInfo } from '@azgaz/azgaz-ui';
import { Components } from '@/apps/appCreator/outsideComponents';
import './style.scss';

const CarsItemDealer: Components['CarsItemDealer'] = ({ car }) => {
  const { dealer, office_ids } = car;
  const currentOffice = dealer.offices.find(
    ({ id }: { id: string }) => id === office_ids[0]
  );
  const dealerInfo =
    currentOffice && `${currentOffice.name}, ${currentOffice.city}`;
  return <DealerInfo>{dealerInfo}</DealerInfo>;
};

export default CarsItemDealer;
