import axios from 'axios';
import { config } from '@/utils';

const carstockApi = config.get('carstockApi');
const dealer = config.get('dealer');

const api = axios.create({ baseURL: carstockApi });

export default (queryParams: string) => {
  return api.get(`vehicles/?dealer=${dealer}&${queryParams}`);
};
