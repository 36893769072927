/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from '@reduxjs/toolkit';
import { Car } from 'azgaz-connect';

export type TService = {
  name: string;
  descr: string;
  full_descr: string;
  price: string;
  limited: {
    model: string | null;
    dealers: string[];
    dealers_action: 'show' | 'show_limited' | 'hide' | 'hide_limited';
    grade: string[];
  };
};

export default createSelector(
  (car: Car | undefined) => car,
  car => {
    if (!car) {
      return [];
    }

    const { grade: carGrade, model: carModel, dealer } = car;
    const services: TService[] = car?.services || [];

    const filteredServices = services.filter(service => {
      const { grade, model, dealers, dealers_action } = service.limited;
      let isDealerMath = false;

      // eslint-disable-next-line default-case
      switch (dealers_action) {
        case 'show':
          isDealerMath = true;
          break;
        case 'show_limited':
          isDealerMath = dealers?.length ? dealers.includes(dealer.id) : false;
          break;
        case 'hide':
          isDealerMath = false;
          break;
        case 'hide_limited':
          isDealerMath = dealers?.length ? !dealers.includes(dealer.id) : true;
          break;
      }

      const isGradeMatch = grade.length ? grade.includes(carGrade) : true;
      const isModelMatch = model ? model === carModel : true;

      return isGradeMatch && isModelMatch && isDealerMath;
    });
    return filteredServices;
  }
);
