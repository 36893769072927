/* eslint-disable import/no-cycle */
import React, { FC } from 'react';

import {
  CarsItemImg,
  CarsItemInfo,
  CarsItemOptions,
  CarsItemFinance,
  CarsItemAssign,
  CarsItemCredit,
  getSpecification,
  ColorOption,
  CarsItemPrice,
} from 'azgaz-ui';

import { Car, selectors } from 'azgaz-connect';
import { config } from '@/utils';
import outsideComponents from '../../outsideComponents';
import CarsItemFavouriteBtn from '../CarsItemFavouriteBtn';
import CarsItemWrap from '../CarsItemWrap';
import { getCarPriceInfo } from '../../selectors';

type CarsItemProps = {
  car: Car;
};

const CarsItem: FC<CarsItemProps> = ({ car }) => {
  const { CarsItemDealer } = outsideComponents;
  const { model, grade, specification } = car;
  const imgAttrs = selectors.getCarFirstThumb(car);
  const assign = getSpecification(specification, 'main', 'use');
  const creditPrice: number = car?.user_metadata?.credit_price || 0;
  const { price: carPrice } = getCarPriceInfo(car);
  const leasingPrice = car?.price?.leasing_price;
  const isHasBothPayments = !!(leasingPrice && creditPrice);
  const isNoPayments = !leasingPrice && !creditPrice;

  const fields = [
    {
      source: 'specification',
      id: 'engine_type',
    },
    {
      source: 'specification',
      id: 'drive_type',
    },
    {
      source: 'specification',
      id: 'load_capacity',
    },
    {
      source: 'root',
      id: 'year',
      title: 'Год выпуска',
    },
    {
      source: 'specification',
      id: 'weight',
    },
    {
      source: 'specification',
      id: 'passangers',
      title: 'Пассажировместимость',
    },
    {
      source: 'specification',
      id: 'chassis_length',
    },
  ];

  return (
    <CarsItemWrap car={car}>
      <CarsItemImg src={imgAttrs.url} alt={imgAttrs.alt} />
      <CarsItemInfo
        title={
          <>
            {model}
            <span className="azgazui__cars-item-name-grade">{grade}</span>
          </>
        }
      >
        {config.get('featureFlagFavourites') && (
          <CarsItemFavouriteBtn car={car} />
        )}
        <CarsItemAssign>{assign}</CarsItemAssign>
        <CarsItemOptions car={car} fields={fields} />
        <ColorOption color={car.color} />
        <CarsItemFinance>
          <CarsItemPrice
            price={carPrice}
            hasDiscounts={false}
            displayAsRow={isHasBothPayments || isNoPayments}
          />
          {/* TODO: Временно скрыто
            <CarsItemCredit
              creditPayment={creditPrice}
              leasingPayment={leasingPrice}
            /> 
          */}
        </CarsItemFinance>
        {CarsItemDealer && <CarsItemDealer car={car} />}
      </CarsItemInfo>
    </CarsItemWrap>
  );
};

export default CarsItem;
