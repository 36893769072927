import axios from 'axios';
import { config } from '@/utils';

/**
 * status принимает значения:
 * default - если что-то не так с параметрами запроса;
 * success - если авто нет в базе залога;
 * danger - если авто в залоге, также прилетает заполненным поле date - там указана дата внесения авто в базу залога.
 */

export type TStatus = 'default' | 'danger' | 'success';

type TResponse = {
  status: TStatus;
  date: boolean | Date | string;
};

const vinApi = config.get('vinApi');

const api = axios.create({ baseURL: vinApi });

export default (vin: string) =>
  api.get<TResponse>(`?vinInput=${vin}&perx=y`);
