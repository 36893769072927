import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { themeContext } from 'azgaz-ui';
import createCarstockProvider, { utils } from 'azgaz-connect';
import { config, generateReqParamsCarsRelated } from '@/utils';
import store from './store';
import outsideComponents from './outsideComponents';
import App from './components/App';

const browserHistory = createBrowserHistory({
  basename: config.get('basename'),
});
const featureFlagCarsRelated = config.get('featureFlagCarsRelated');
const carsRelated = config.get('carsRelated') || {};
carsRelated.getReqParams = generateReqParamsCarsRelated;

const carsSeen = config.get('carsSeen');
const carGroups = config.get('carGroups') || [{}];

const { ThemeProvider } = themeContext;

type AppCreatorProps = {
  filtersConfig: utils.PatchedFiltersConfig;
  azgazUiTheme: themeContext.ThemeType;
  components: typeof outsideComponents;
};

function appCreator({
  filtersConfig,
  azgazUiTheme,
  components,
}: AppCreatorProps) {
  const CarstockProvider = createCarstockProvider({
    carStockApi: ENV.carstockApi,
    filtersConfig,
    thumbId: 101,
    history: browserHistory,
    defaultSort: '-state_active_at',
    storagePrefix: carGroups[0].id,
    carsSeen,
    ...(featureFlagCarsRelated && { carsRelated }),
  });

  Object.assign(outsideComponents, components);

  const AppCreator: FC = () => {
    return (
      <Provider store={store}>
        <ThemeProvider value={azgazUiTheme}>
          <CarstockProvider>
            <Router history={browserHistory}>
              <App />
            </Router>
          </CarstockProvider>
        </ThemeProvider>
      </Provider>
    );
  };

  return AppCreator;
}

export default appCreator;
