import React, { FC, useState, useCallback } from 'react';
import SmoothScroll from 'smooth-scroll';
import { Car } from 'azgaz-connect';
import { Button } from 'azgaz-ui';

import type { TDealerOffice } from '@/types';
import CarPresentation from '../CarPresentation';
import Popover from '../Popover';

type TProps = {
  car: Car | undefined;
  dealerOffices: TDealerOffice[];
};

const smoothScroll = new SmoothScroll(undefined, {
  updateURL: false,
  speed: 500,
  speedAsDuration: true,
});

const CarPresentationLink: FC<TProps> = ({ car, dealerOffices }) => {
  const [visibleForm, toggleForm] = useState(false);

  const toggleVisible = useCallback((visible: boolean, callback?: Function) => {
    toggleForm(visible);

    // только для мобильных вьюпортов
    if (visible && window.matchMedia('(max-width: 767px)').matches) {
      smoothScroll.animateScroll(document.getElementById('btn-presentation'));
    }
    if (callback) {
      callback();
    }
  }, []);

  return car ? (
    <Popover
      placement="bottom"
      title="Хочу онлайн-презентацию"
      trigger="click"
      visible={visibleForm}
      onVisibleChange={toggleVisible}
      content={
        <CarPresentation
          car={car}
          dealerOffices={dealerOffices}
          onVisibleChange={toggleVisible}
        />
      }
    >
      <div id="btn-presentation">
        <Button size="sm" transparent>Онлайн-презентация</Button>
      </div>
    </Popover>
  ) : null;
};

export default CarPresentationLink;
