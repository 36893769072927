import React, { FC } from 'react';
import { useSelector, selectors, useDispatch, utils } from 'azgaz-connect';
import { CarsList as AuiCarsList } from 'azgaz-ui';

import { config } from '@/utils';
import CarsItem from '../CarsItem';

const CarsList: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFiltersValues);
  const cars = useSelector(selectors.getCars);
  const { isZeroPage } = useSelector(selectors.getIsPageLoading);
  const changedRangeFilters = useSelector(selectors.getChangedRangesFilters);

  const uiHelper = new utils.UiHelper({
    filters,
    dispatch,
  });

  const isCarGroupsNew = config
    .get('carGroups')
    .find(({ id }: { id: string }) => id === 'new');

  const carsEmptyTxt = (
    <p>
      {isCarGroupsNew
        ? 'К сожалению, не найдено ни одного автомобиля в наличии'
        : 'К сожалению, не найдено ни одного автомобиля с пробегом в наличии'}
    </p>
  );

  return (
    <AuiCarsList
      cars={cars}
      carsEmptyText={changedRangeFilters.length ? '' : carsEmptyTxt}
      isZeroPageLoading={isZeroPage}
      clearFilters={() => uiHelper.resetFilters()}
      Item={CarsItem}
    />
  );
};

export default CarsList;
