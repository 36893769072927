import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import {
  useSelector as useSelectorConnect,
  selectors as selectorsConnect,
  Car,
} from '@azgaz/azgaz-ui/connect';

type TPopoverAction = 'delete' | 'add';

export default function useFavoritesTitle(car: Car | undefined) {
  const [favouritesPopoverTxt, setFavouritesPopoverTxt] = useState('');

  const favouritesEntities = useSelectorConnect(
    selectorsConnect.favourites.favouritesEntities
  );

  const getPopoverContent = (action: TPopoverAction) => {
    if (action === 'add') {
      return isMobile ? 'Добавлено в избранное' : 'Удалить из избранного';
    }
    return isMobile ? 'Удалено из избранного' : 'Добавить в избранное';
  };

  useEffect(() => {
    if (car) {
      const action = favouritesEntities[car.id] ? 'add' : 'delete';
      const txt = getPopoverContent(action);

      setFavouritesPopoverTxt(txt);
    }
  }, [favouritesEntities]);

  return {
    favouritesPopoverTxt,
    favouritesEntities,
  };
}
