export default {
  validateConfig: ['dealer', 'carGroups'],
  bookUrl: ENV.bookUrl,
  carstockApi: ENV.carstockApi,
  formApi: ENV.formApi,
  pdfApi: ENV.pdfApi,
  orderApi: ENV.orderApi,
  vinApi: ENV.vinApi,
  dadataApi: ENV.dadataApi,
  dadataApiKey: ENV.dadataApiKey,

  // Фича флаги в формате ENV.flagName === 'on':
  featureFlagTestTruckButton: ENV.featureFlagTestTruckButton === 'on',
  featureFlagCarsRelated: ENV.featureFlagCarsRelated === 'on',
  featureFlagCarsSeen: ENV.featureFlagCarsSeen === 'on',
  featureFlagFavourites: ENV.featureFlagFavourites === 'on',

  // Скидка по промокоду в рублях
  promocodeDiscount: 30000,
};
