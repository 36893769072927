import { useReducer } from 'react';
import { TSuggestion } from '@/api/dadataApi';

export type SuggestionsState = {
  loading: {
    [key: string]: boolean;
  };
  list: TSuggestion[];
  error?: string;
};

const suggestionsInitialState: SuggestionsState = {
  list: [],
  loading: {
    byInn: false,
    byName: false,
  },
};

const suggestionsTypes = {
  start: 'start',
  success: 'success',
  error: 'error',
} as const;

const suggestionsReducer = (
  state: SuggestionsState,
  action: { type: keyof typeof suggestionsTypes; payload?: any; error?: string }
): SuggestionsState => {
  switch (action.type) {
    case suggestionsTypes.start:
      return {
        ...state,
        loading: { ...state.loading, [action.payload]: true },
        error: undefined,
      };
    case suggestionsTypes.success:
      return {
        ...state,
        loading: {
          byInn: false,
          byName: false,
        },
        list: action.payload,
      };
    case suggestionsTypes.error:
      return {
        ...state,
        loading: {
          byInn: false,
          byName: false,
        },
        error: action.error,
      };
    default:
      throw new Error('incompatible action type');
  }
};

export const suggestionsActions = {
  start(typeLoading: string) {
    return {
      type: suggestionsTypes.start,
      payload: typeLoading,
    };
  },
  success(payload: SuggestionsState['list']) {
    return {
      type: suggestionsTypes.success,
      payload,
    };
  },
  error(error: SuggestionsState['error']) {
    return {
      type: suggestionsTypes.error,
      error,
    };
  },
};

export default function useSuggestions() {
  const [suggestions, dispatchSuggestions] = useReducer(
    suggestionsReducer,
    suggestionsInitialState
  );

  return {
    suggestions,
    dispatchSuggestions,
    hasSuggestions: suggestions.list.length > 0,
  };
}
