import { State } from '../store';
import { TFields } from '../store/order';

export default (state: State) =>
  Object.entries(state.order.fields).reduce<
    { [K in keyof TFields]: TFields[K]['value'] }
  >((acc, [key, rcValue]) => {
    acc[key] = rcValue.value;
    return acc;
  }, {} as { [K in keyof TFields]: TFields[K]['value'] });
