/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import AntdPopover, { PopoverProps } from 'antd/lib/popover';
import cn from 'classnames';
import 'antd/lib/popover/style/index.css';
import style from './style.module.scss';

export { PopoverProps } from 'antd/lib/popover';

const Popover: FC<PopoverProps> = ({
  children,
  overlayClassName,
  ...restProps
}) => {
  return (
    <AntdPopover
      overlayClassName={cn(style.popover, overlayClassName)}
      {...restProps}
    >
      {children}
    </AntdPopover>
  );
};

export default Popover;
