export type TFormFields = { [key: string]: any };

/**
 * Приводит объект с данными к виду пригодному для перечи в сервис форм
 * @param fields Объект с данными для передачи в СФ (сервис форм)
 */
export default function resolveFsFields(fields: TFormFields) {
  const resolvedFields = Object.keys(fields).reduce<TFormFields>(
    (acc, fieldName) => {
      const value = fields[fieldName];

      // Пустые строки не должны передаваться т.к ФС начинает их валидизировать на minLength
      if (typeof value === 'string') {
        const resolvedValue = value.trim();

        if (resolvedValue !== '') {
          acc[fieldName] = resolvedValue;
        }
      } else {
        acc[fieldName] = fields[fieldName];
      }

      return acc;
    },
    {}
  );

  return resolvedFields;
}
