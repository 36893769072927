import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { Car } from 'azgaz-connect';
import { vinRequest } from '@/api';
import { TStatus } from '@/api/vinRequest';

import style from './style.module.scss';

type TProps = {
  car: Car;
};

const text = {
  success: 'Данных о залоге не найдено, уточните у дилера',
  danger: 'Возможно, автомобиль в залоге, уточните у дилера',
};

const CarVinInfo: FC<TProps> = ({ car: { vin } }) => {
  const [vinRequestStatus, setVinRequestStatus] = useState<TStatus>('default');

  useEffect(() => {
    vinRequest(vin).then(res => {
      const { status } = res.data;
      setVinRequestStatus(status);
    });
  }, []);

  if (vinRequestStatus === 'default') {
    return null;
  }

  return (
    <div className="mb-3">
      <div className={style.title}>проверка по vin</div>
      <div className={style.text}>
        <span className={cn(style.icon, style[vinRequestStatus])} />
        {text[vinRequestStatus]}
      </div>
    </div>
  );
};

export default CarVinInfo;
