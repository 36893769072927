/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { Car } from 'azgaz-connect';
import { ValueOf } from '@/types';
import { formServiceRequest, pdfServiceRequest } from '@/api';
import { download, config, applyOnSubmitHandler } from '@/utils';
import { GetState } from '../index';
import { getOrderHtmlLink, getOrderFsData } from '../../selectors';

export const userTypes = {
  company: 'company',
  personal: 'personal',
} as const;

export type TUserTypes = ValueOf<typeof userTypes>;

// Более точная интерпритация, но работать с этим будет сложнее
// type TField<N, T> = { name: N; value: T };
export type TField<T> = { value: T };

export const useCases = {
  createOrder: 'createOrder',
} as const;

export const fields = {
  userType: 'userType',
  userFIO: 'userFIO',
  userName: 'userName',
  userSurname: 'userSurname',
  userPatronymic: 'userPatronymic',
  userPhone: 'userPhone',
  userEmail: 'userEmail',
  userComment: 'userComment',
  companyName: 'companyName',
  companyPhone: 'companyPhone',
  companyAddress: 'companyAddress',
  inn: 'inn',
  kpp: 'kpp',
  termPersonalData: 'termPersonalData',
  termGettingInfo: 'termGettingInfo',
  termRulesInfo: 'termRulesInfo',
  termOrderAgreement: 'termOrderAgreement',
  dealerOfficeId: 'dealerOfficeId',
} as const;

export type TFields = {
  [fields.userType]: TField<TUserTypes>; // тип лица оформляющего заявку
  [fields.userFIO]: TField<string>; // ФИО
  [fields.userName]: TField<string>; // Имя
  [fields.userSurname]: TField<string>; // Фамилия
  [fields.userPatronymic]: TField<string>; // Отчество
  [fields.userPhone]: TField<string>; // Телефон пользователя
  [fields.userEmail]: TField<string>;
  [fields.userComment]: TField<string>;
  [fields.companyName]: TField<string>; // Название организации
  [fields.companyPhone]: TField<string>;
  [fields.companyAddress]: TField<string>;
  [fields.inn]: TField<string>; // ИНН
  [fields.kpp]: TField<string>; // КПП
  [fields.termPersonalData]: TField<boolean>; // Согласие на обработку персональных данных
  [fields.termGettingInfo]: TField<boolean>; // Согласие на получение информации
  [fields.termOrderAgreement]: TField<boolean>; // Согласие c договором оферты
  [fields.termRulesInfo]: TField<boolean>; // Согласие с Правилами оказания услуг
  [fields.dealerOfficeId]: TField<string>; //
};

type SliceStateOrder = {
  formSuccessfullySent: boolean;
  fields: TFields;
  loading: {
    pdf: boolean;
    fs: boolean;
    common: boolean;
  };
  error: {
    pdf?: string;
    fs?: string;
  };
};

const initialState: SliceStateOrder = {
  formSuccessfullySent: false,
  fields: {
    userType: {
      value: userTypes.company,
    },
    [fields.userFIO]: {
      value: '',
    },
    [fields.userName]: {
      value: '',
    },
    [fields.userSurname]: {
      value: '',
    },
    [fields.userPatronymic]: {
      value: '',
    },
    [fields.userPhone]: {
      value: '',
    },
    [fields.userEmail]: {
      value: '',
    },
    [fields.userComment]: {
      value: '',
    },
    [fields.companyName]: {
      value: '',
    },
    [fields.companyPhone]: {
      value: '',
    },
    [fields.companyAddress]: {
      value: '',
    },
    [fields.inn]: {
      value: '',
    },
    [fields.kpp]: {
      value: '',
    },
    [fields.termPersonalData]: {
      value: false,
    },
    [fields.termGettingInfo]: {
      value: false,
    },
    [fields.termOrderAgreement]: {
      value: false,
    },
    [fields.termRulesInfo]: {
      value: false,
    },
    [fields.dealerOfficeId]: {
      value: '',
    },
  },
  loading: {
    pdf: false,
    fs: false,
    common: false,
  },
  error: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setFields(
      state,
      action: PayloadAction<{ [K in keyof Partial<TFields>]: TFields[K] }>
    ) {
      state.fields = {
        ...state.fields,
        ...action.payload,
      };
    },
    pdfRequest(state) {
      state.error.pdf = undefined;
      state.loading.pdf = true;
      state.formSuccessfullySent = false;
    },
    pdfSuccess(state) {
      state.loading.pdf = false;
      state.loading.common = false;
      state.formSuccessfullySent = true;
      // TODO: тут по идее должна прилетать ссылка на скачивание
    },
    pdfError(state) {
      state.error.pdf = 'Ошибка генерации PDF';
      state.loading.pdf = false;
      state.loading.common = false;
    },
    fsRequest(state) {
      state.error.fs = undefined;
      state.loading.fs = true;
      state.loading.common = true;
      state.formSuccessfullySent = false;
    },
    fsSuccess(state) {
      state.loading.fs = false;
    },
    fsError(state) {
      state.error.fs = 'К сожалению, отправка данных не удалась';
      state.loading.fs = false;
      state.loading.common = false;
    },
    clearFormMessage(state) {
      state.error.fs = undefined;
      state.error.pdf = undefined;
      state.formSuccessfullySent = false;
    },
  },
});

const createOrder = (
  car: Car | undefined,
  officeDatasourceId: string | undefined
) => async (dispatch: Dispatch, getState: GetState) => {
  const getPdfFileOrder = async () => {
    try {
      const htmlLink = getOrderHtmlLink(getState(), car);

      if (!htmlLink) {
        dispatch(orderSlice.actions.pdfError());
        return;
      }

      dispatch(orderSlice.actions.pdfRequest());
      const blobData = await pdfServiceRequest(htmlLink);
      download(blobData.data, 'счет_на_предоплату.pdf');
      dispatch(orderSlice.actions.pdfSuccess());
    } catch (e) {
      dispatch(orderSlice.actions.pdfError());
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  try {
    let fsData = getOrderFsData(getState(), car);

    fsData =
      fsData && applyOnSubmitHandler(fsData, config.get('formOnSubmitOrder'));

    if (!fsData) {
      dispatch(orderSlice.actions.fsError());
      return;
    }

    const resolvedFsData = {
      ...fsData,
      ...(config.get('officesDatasourceId') &&
        officeDatasourceId && {
          dealerOffice: officeDatasourceId,
        }),
    };

    dispatch(orderSlice.actions.fsRequest());
    await formServiceRequest(config.get('formIdOrder'), resolvedFsData);
    dispatch(orderSlice.actions.fsSuccess());

    getPdfFileOrder();
  } catch (e) {
    dispatch(orderSlice.actions.fsError());
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const orderActions = {
  ...orderSlice.actions,
  createOrder,
};

export default orderSlice;
