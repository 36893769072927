/* eslint-disable @typescript-eslint/camelcase */
import React, { FC } from 'react';
import { useStore } from 'react-redux';
import cn from 'classnames';
import numberToWordsRu from 'number-to-words-ru';

import { Car } from 'azgaz-connect';
import { numberFormat } from 'azgaz-ui';
import { getDealerRequisites } from '@/apps/appCreator/selectors';
import { config } from '@/utils';

import style from './style.module.scss';

type TProps = {
  car: Car | undefined;
};

const RenderRulesReservation: FC<TProps> = ({ car }) => {
  const store = useStore();
  if (!car) {
    return null;
  }
  const dealerRequisites = getDealerRequisites(store.getState(), car);
  const currSiteUrl = window.location.origin;
  const { hostname } = window.location;

  const dealerUrl = (
    <a href={currSiteUrl} target="_blank" rel="noreferrer">
      {currSiteUrl}
    </a>
  );
  const rulesReservationUrl = config.get('rulesReservationUrl');
  const rulesUrl = (
    <a
      href={`${currSiteUrl}${rulesReservationUrl}`}
      target="_blank"
      rel="noreferrer"
    >{`${currSiteUrl}${rulesReservationUrl}`}</a>
  );

  const {
    provider_name,
    provider_address,
    provider_inn,
    provider_kpp,
    provider_phone,
    bank_fullname,
    bank_name,
    bank_inn,
    bank_kpp,
    bank_bik,
    bank_correspondent_account,
    bank_checking_account,
    prepayment,
  } = dealerRequisites || {};

  const formatedPrepayment = numberFormat()(prepayment || 0);
  const prepaymentByWords = numberToWordsRu.convert(prepayment || 0, {
    showNumberParts: {
      integer: true,
      fractional: false,
    },
  });

  return (
    <>
      <h2 className={style.h2}>
        Правила оказания услуг по резервированию товаров на сайте {hostname}
      </h2>

      <p>
        {provider_name} (ИНН {provider_inn}), по средствам Сервиса Онлайн Шоурум
        на сайте, расположенного по адресу: {dealerUrl} (далее по тексту -
        «Продавец»), предоставляет своим Клиентам возможность поиска и выбора
        интересующего их Товара, с возможностью последующего оформления заказа с
        целью резервирования Товара Продавца на определенный срок и его
        приобретения в Автосалонах Продавца на нижеследующих условиях настоящих
        Правил:
      </p>

      <ol>
        <li>
          <h3 className={style.h3}>Термины и определения.</h3>
          <p>
            1.1. «Сайт» - сайт Продавца, расположенный по адресу: {dealerUrl} .
          </p>
          <p>1.2. «Клиент» -</p>
          <p>
            1.2.1. Полностью дееспособное физическое лицо, достигшее возраста 18
            лет, выразившее намерение приобрести Товар с помощью Сервиса Онлайн
            Шоурум на Сайте для личных, семейных, домашних и иных нужд, не
            связанных с осуществлением предпринимательской деятельности, а также
            заключить с Продавцом Договор купли-продажи Товара, в том числе на
            условиях, содержащихся в настоящих Правилах, выступая на стороне
            Покупателя
          </p>
          <p>
            1.2.2. Юридическое лицо или индивидуальный предприниматель, имеющий
            намерение приобрести товар с помощью сервиса с помощью Сервиса
            Онлайн Шоурум на Сайте для использования в предпринимательской
            деятельности или в иных целях, не связанных с личным, семейным,
            домашним и иным подобным использованием, в том числе на условиях,
            содержащихся в настоящих Правилах, выступая на стороне Покупателя.
          </p>
          <p>
            1.3. «Заявка» - запрос Клиента на резервирование Товара Продавца,
            оформленный в соответствии с настоящими Правилами.
          </p>
          <p>
            1.4. Онлайн Шоурум — Сервис на сайте Продавца, предназначенный для
            использования возможности резервирования автомобилей на определенный
            срок и оплаты Товара, с целью дальнейшего заключения договора
            купли-продажи Товара между Клиентом и Продавцом.
          </p>
          <p>
            1.5. «Акцепт» — полное и безоговорочное принятие Покупателем условий
            настоящих Правил, выраженное путем оформления Заявки и осуществления
            оплаты в счет резервирования Товара.
          </p>
          <p>
            1.6. «Обеспечительный платеж» - денежные средства Клиента в пользу
            Продавца при оформлении Заявки на Сайте, оплачиваемые в соответствии
            с Разделом 5 настоящих Правил. Внесенный Обеспечительный платеж,
            после поступления денежных средств на счет Продавца, обеспечивает
            право требования Клиента к Продавцу заключить договор купли-продажи
            Товара, в течение установленного срока резервирования Товара.
          </p>
          <p>
            1.7. «Резервирование товара» - услуга, оказываемая Продавцом Клиенту
            по внесению Клиентом обеспечительного платежа за дистанционно
            выбранный Товар; обеспечивающего право требования Клиента к Продавцу
            заключить договор купли-продажи Товара, в течение установленного
            срока резервирования Товара.
          </p>
          <p>
            1.8. «Товар» — перечень автомобилей, представленный в Онлайн
            Шоуруме, в отношении которых может быть совершена сделка
            купли-продажи между Продавцом и Клиентом на условиях настоящих
            Правил.
          </p>
          <p>
            1.9. «Каталог» — информация о Товарах, размещенная в Онлайн Шоуруме.
            Каждая единица Товара сопровождается Карточкой Товара, содержащей
            общую информацию о его характеристиках.
          </p>
          <p>
            1.10. «Электронная подпись» (ЭП) - информация в электронной форме,
            которая присоединена к другой информации в электронной форме
            (подписываемой информации) или иным образом связана с такой
            информацией и которая используется для определения лица,
            подписывающего информацию;
          </p>
        </li>
        <li>
          <h3 className={style.h3}>Общие положения.</h3>
          <p>
            2.1. Продавец осуществляет продажу Товаров любому Клиенту,
            оформившему Заявку на условиях настоящих Правил.
          </p>
          <p>
            2.2. К правоотношениям Сторон в связи с пользованием Клиентом
            сервиса Онлайн Шоурум на сайте Продавца {dealerUrl} применяются
            положения главы 39 ГК РФ в части оказания услуги по резервированию
            Товара.
          </p>
          <p>
            2.3. Договор купли-продажи Товара признается заключенным, а
            обязанность передачи Товара Покупателю наступившей, с момента
            подписания Сторонами единого документа – Договора купли-продажи
            автомобиля в письменной форме на бумажном носителе в месте
            нахождения Автосалона Продавца, либо с момента подписания Сторонами
            договора купли-продажи Товара в электронной форме с использованием
            ЭП при наличии технической возможности с обеих сторон.
          </p>
          <p>
            2.4. Все информационные материалы, представленные на Сайте Продавца,
            в отношении возможного к резервированию Товара не являются публичной
            офертой Продавца по смыслу ст. 435, 437 Гражданского кодекса РФ,
            носят исключительно справочный характер и не могут в полной мере
            передавать достоверную информацию об определенных свойствах и
            технических характеристиках Товара, включая цвета, размеры и формы.
            Сопровождающие Товар фотографии на Сайте являются примерными
            иллюстрациями к нему и могут отличаться от фактического внешнего
            вида Товара.
          </p>
          <p>
            2.5. Цены, указанные в Карточке товара, могут отличаться от цен,
            действующих в Автосалонах Продавца, в связи с регулярным
            автоматизированным обновлением цен, номенклатуры, наличия Товара в
            Автосалонах Продавца и вероятностью технических сбоев (временных
            разрывов) при выгрузке вышеуказанной информации. При этом цена
            Товара, подлежащая уплате Клиентом за Товар, зарезервированный
            посредством сервиса Онлайн Шоурум, определяется в момент заключения
            Сторонами договора купли-продажи Товара как цена, действующая в
            Автосалонах Продавца.
          </p>
          <p>
            2.6. В случае возникновения у Клиента вопросов, касающихся
            стоимости, свойств и характеристик, наличия Товара, перед
            оформлением Заявки Клиент имеет право обратиться за необходимой
            информацией по телефону Продавца либо уточнить интересующую
            информацию в Автосалонах Продавца.
          </p>
          <p>
            2.7. Пользуясь сервисом Онлайн Шоурум на сайте {dealerUrl} Клиенты
            (Пользователи) выражают свое безусловное согласие с настоящими
            Правилами в целом, путем присоединения по смыслу ч. 1 ст. 428 ГК РФ,
            в связи с чем Клиенту, заинтересованному в покупке товара через
            сервис Онлайн Шоурум на сайте {dealerUrl} настоятельно рекомендуется
            внимательно ознакомиться с условиями настоящих Правил, а в случае
            несогласия с каким-либо из его условий, предлагается отказаться от
            дальнейшей покупки Товаров, посредством сервиса на сайте {dealerUrl}
            .
          </p>
          <p>
            2.8. Продавец вправе в любое время без уведомления Пользователя
            (Клиента) изменить условия настоящих Правил. Действующая редакция
            настоящих Правил размещается по адресу: {rulesUrl}.
          </p>
          <p>
            2.9. Срок действия Правил в отношении позиций Товара Каталога,
            представленного в Онлайн Шоуруме в любом случае ограничен сроком
            наличия соответствующих позиций на складе Продавца и иными условиями
            настоящих Правил.
          </p>
          <p>
            2.10. Все сведения, предоставленные Клиентом в связи с оказанием
            услуги должны быть достоверными. Клиент отвечает за правильность,
            достоверность и полноту передаваемых им Продавцу сведений и иной
            информации.
          </p>
          <p>
            2.11. При использовании недостоверных и/или неполных сведений и иной
            информации, полученных от Клиента, Продавец не несет ответственности
            за негативные последствия, вызванные его действиями на основании
            предоставленных недостоверных и/или неполных сведений и иной
            информации.
          </p>
          <p>
            2.12. Вся текстовая информация и графические изображения,
            находящиеся на Сайте, являются собственностью Продавца.
          </p>
          <p>
            2.13. Использование любой текстовой информации, а равно графических
            изображений, размещённых на сайте, без предварительного письменного
            согласия их правообладателя, является правонарушением,
            ответственность за которое предусмотрена ст.14.10 Кодекса об
            административных правонарушениях Российской Федерации.
          </p>
          <p>
            2.14. За нарушение авторских и смежных прав также предусмотрена
            имущественная и уголовная ответственность в соответствии с
            действующим законодательством Российской Федерации.
          </p>
        </li>
        <li>
          <h3 className={style.h3}>Услуга по Резервированию Товара.</h3>
          <p>
            3.1. Предметом правоотношений Сторон является оказание Клиенту
            услуги дистанционного демонстрирования примерного Каталога Товаров
            Продавца с возможностью его резервирования посредством специального
            технического функционала Онлайн Шоурум, для последующего совершения
            сделки купли-продажи, а Клиент, ознакомившись с Товаром, имеет право
            его дистанционного выбора и право воспользоваться услугой
            резервирования.
          </p>
          <p>
            3.2. Комплекс совершаемых Продавцом действий в рамках оказания
            услуги составляет:
          </p>
          <p>
            3.2.1. Предоставление Клиенту возможности дистанционного просмотра и
            выбора Товара Продавца;
          </p>
          <p>
            3.2.2. Предоставление Клиенту возможности резервирования выбранного
            Товара, путем дистанционного оформления Заявки и внесения
            обеспечительного платежа, дающего Клиенту право требования от
            Продавца заключения с ним договора купли-продажи Товара в период
            срока резервирования.
          </p>
          <p>
            3.3. Резервирование Товара производится Продавцом исключительно
            после поступления от Клиента обеспечительного платежа.
          </p>
          <p>3.4. Условия оказания услуги Продавцом:</p>
          <p>
            3.4.1. Резерв Товара действует в течение 2-х (Двух) рабочих дней, с
            момента получения от Клиента обеспечительного платежа на расчетный
            счет Продавца, и в течение которых Клиент обязуется прибыть в
            Автосалон Продавца для заключения договора купли-продажи Товара,
            либо подписать договор купли-продажи Товара с использованием ЭП при
            наличии технической возможности с обеих сторон;
          </p>
          <p>
            3.4.2. Услуга по резервированию Товара признается оказанной
            Продавцом с момента оплаты счета (п.5.1.) Клиентом и осуществленного
            на этом основании резерва на срок, указанный в п.3.4.1.
          </p>
          <p>
            3.4.3. Клиент соглашается с тем, что по истечении срока действия
            резерва и не заключения договора купли-продажи Товара с Клиентом по
            независящим от Продавца причинам, Продавец имеет право аннулировать
            резерв в одностороннем порядке и выставить Товар на продажу без
            дополнительного уведомления Клиента;
          </p>
          <p>
            3.4.4. Договор купли-продажи может быть заключен только в отношении
            Товара, не находящегося в резерве и только с Клиентом, осуществившим
            обеспечительный платеж;
          </p>
          <p>
            3.4.5. Клиент настоящим проинформирован, что обеспечительный платеж,
            в случае заключения Клиентом Договора купли-продажи
            зарезервированного Товара в соответствии с настоящими Правилами,
            может быть зачтен Продавцом в счет стоимости автомобиля. Условия
            зачета предусматриваются по соглашению сторон в Договоре
            купли-продажи.
          </p>
        </li>
        <li>
          <h3 className={style.h3}>Стоимость (цена) товара.</h3>
          <p>
            4.1. Стоимость Товара отображена в Карточке Товара, сопровождаемой
            каждую позицию Каталога в Онлайн Шоуруме {dealerUrl}, указывается в
            рублях Российской Федерации.
          </p>
          <p>
            4.2. Стоимость на любые позиции Товара могут быть изменены Продавцом
            в одностороннем порядке. В случае изменения стоимости в период срока
            резервирования на позиции Товара, по которым от Клиента поступила
            Заявка на резервирование, Продавец обязуется проинформировать
            Клиента о таком изменении. Клиент вправе подтвердить либо
            аннулировать свою Заявку. В случае отсутствия связи с Клиентом, его
            Заявка считается аннулированной в течение 2-х (Двух) календарных
            дней с момента оформления.
          </p>
        </li>
        <li>
          <h3 className={style.h3}>Оплата Обеспечительного платежа.</h3>
          <p>
            5.1. Оплата обеспечительного платежа производится по реквизитам
            Продавца, указанным в Счете. Для получения счета на соответствующей
            странице необходимо нажать кнопку «Получить счет».
          </p>
          <p>
            5.2. Размер обеспечительного платежа устанавливается в размере{' '}
            {formatedPrepayment} ({prepaymentByWords}) .
          </p>
          <p>
            5.3. Оплата обеспечительного платежа предполагает выполнение
            следующих действий:
          </p>
          <p>
            5.3.1. Клиент на сайте {dealerUrl} выбирает Товар для дистанционного
            резервирования;
          </p>
          <p>
            5.3.2. Подтверждает своё действие согласием с настоящими Правилами
            нажатием на кнопку «Получить счет»;
          </p>
          <p>
            5.3.3. После заполнения Клиентом обязательных полей на сайте{' '}
            {dealerUrl} формируется счет на оплату обеспечительного платежа с
            возможностью сохранения его в электронном виде.
          </p>
          <p>
            5.3.4. Клиент в течение 3 (Трех) рабочих дней (срока действия счета)
            обязуется произвести оплату обеспечительного платежа по
            выставленному счету удобным для него способом.
          </p>
          <p>
            5.4. Клиент вправе произвести оплату обеспечительного
            платежа через сайт путем выполнения следующих действий:
          </p>
          <p>
            5.4.1. Клиент заполняет обязательные поля на сайте {dealerUrl} и
            выбирает Товар для дистанционного резервирования;
          </p>
          <p>
            5.4.2. Подтверждает своё действие согласием с настоящими Правилами
            нажатием на кнопку «Оплатить»
          </p>
          <p>
            5.4.3. После нажатия кнопки «Оплатить» Клиент перенаправляется на
            защищенную платежную страницу банка, где необходимо внести данные
            банковской карты для совершения платежа.
          </p>
          <p>
            5.4.4. Прием и обработка платежей с использованием банковских карт
            проводится банком-эквайером, определенным Продавцом. Продавец не
            осуществляет обработку, в том числе сбор и хранение данных
            банковских карт Клиентов.
          </p>
          <p>
            5.4.5. Сразу после совершения платежа Клиент перенаправляется
            обратно на Сайт
          </p>
          <p>
            5.4.6. В качестве подтверждения получения платежа Продавцом Клиент
            получает электронный чек, который содержит все данные о проведенной
            платежной транзакции.
          </p>
        </li>
        <li>
          <h3 className={style.h3}>
            Порядок возврата обеспечительного платежа.
          </h3>
          <p>6.1. Условия возврата денежных средств:</p>
          <p>
            6.1.1. Возврат денежных средств возможен при отказе от заключения
            договора купли-продажи зарезервированного Товара Клиентом.
          </p>
          <p>
            6.1.2. Возврат денежных средств осуществляется только на банковскую
            карту либо расчетный счет, с которого была произведена оплата
            обеспечительного платежа.
          </p>
          <p>
            6.1.3. В случае если срок резервирования, указанный в п. 3.4.1.,
            истек, а оплата автомобиля не была произведена полностью, Продавец
            оставляет за собой право в одностороннем порядке осуществить возврат
            полученных денежных средств на банковскую карту либо расчетный счет,
            с которой была произведена оплата услуги резервирования. В этом
            случае резерв на автомобиль аннулируется, и автомобиль считается
            доступным для дальнейшего резервирования.
          </p>
          <p>
            6.2. Клиент проинформирован о том, что, количество Товара
            ограниченно. В случае, когда на одну позицию поступило несколько
            Заявок, услуга резервирования оказывается Клиенту, от которого
            обеспечительный платеж поступил ранее других.
          </p>
        </li>
        <li>
          <h3 className={style.h3}>Права и обязанности сторон</h3>
          <p>7.1. Продавец обязуется:</p>
          <p>
            7.1.1. Не разглашать любую частную информацию Клиента и не
            предоставлять доступ к этой информации третьим лицам, за исключением
            случаев, предусмотренных законодательством РФ.
          </p>
          <p>
            7.1.2. Продавец оставляет за собой право невыполнения резервирования
            Товара в случае возникновения форс-мажорных ситуаций.
          </p>
          <p>
            7.1.3. Продавец оставляет за собой право изменять условия настоящих
            Правил в одностороннем порядке.
          </p>
          <p>
            7.1.4. Продавец не несет ответственности за последствия, которые
            могут наступить из-за ненадлежащего использования Товара
            Покупателем.
          </p>
          <p>
            7.1.5. Операции по банковским картам совершаются держателем карты
            либо лицом, уполномоченным на основании доверенности, оформленной в
            соответствии с законодательством РФ. Во избежание случаев различного
            рода неправомерного использования банковских карт при оплате, все
            Заявки, оформленные на Сайте и предоплаченные банковской картой,
            проверяются Продавцом. В случае выявления нарушений использования
            банковских карт, а равно получения информации и совершении
            мошеннических действий с банковскими картами Продавец оставляет за
            собой право без объяснения причины аннулировать Заявку.
            Обеспечительный платеж возвращается на банковскую карту владельца, с
            которой произведена оплата.
          </p>
          <p>7.2. Клиент обязуется:</p>
          <p>
            7.2.1. Предоставлять Продавцу достоверные сведения необходимые для
            успешного выполнения Заявки.
          </p>
          <p>
            7.2.2. До момента акцепта ознакомиться с содержанием условий
            настоящих Правил.
          </p>
          <p>
            7.3. Право собственности на Товар, оформленный в рамках Сервиса по
            резервированию Товара, а также риск его случайной гибели или
            повреждения переходят к Клиенту на условиях и в соответствии с
            Договором купли-продажи Товара, заключенным Клиентом с Продавцом.
          </p>
        </li>
        <li>
          <h3 className={style.h3}>Прочие условия.</h3>
          <p>
            8.1. Передача информации и данных о Клиенте, заполняемых при оплате,
            осуществляется Клиентом с помощью безопасного соединения.
          </p>
          <p>
            8.2. Ответственность Продавца перед Клиентом по настоящим Правилам
            ограничивается суммой {formatedPrepayment} ({prepaymentByWords}) .
          </p>
          <p>
            8.3. Продавец не несет ответственности за ущерб, причиненный Клиенту
            вследствие ненадлежащего использования Клиентом либо третьими лицами
            Товаров, заказанных на Сайте.
          </p>
          <p>
            8.4. Продавец вправе переуступать либо каким-либо иным способом
            передавать свои права и обязанности, вытекающие из его отношений с
            Клиентом, третьим лицам.
          </p>
          <p>
            8.5. Споры сторон, возникшие в связи с выполнением условий настоящих
            Правил, разрешаются в ходе взаимных консультаций и переговоров.
          </p>
          <p>
            8.6. В случае не достижения сторонами взаимного согласия, споры
            между ними разрешаются в суде по месту нахождения Продавца.
          </p>
          <p>
            8.7. Вся текстовая информация и графические изображения, размещенные
            на интернет-сайте {dealerUrl}, являются собственностью Продавца
            и/или его поставщиков и производителей Товара. Использование
            материалов Сайта без согласия правообладателей не допускается. При
            цитировании материалов Сайта, включая охраняемые авторские
            произведения, ссылка на Сайт обязательна. Клиент предупрежден о том,
            что Продавец не несет ответственности за посещение и использование
            им внешних ресурсов, ссылки на которые могут содержаться на сайте.
          </p>
        </li>
        <li>
          <h3 className={style.h3}>Реквизиты продавца.</h3>

          <div className={style.flexTable}>
            <div className={style.colTable}>
              <h4 className={style.h4}>Поставщик:</h4>
              <p>Наименование: {provider_name}</p>
              <p>Адрес: {provider_address}</p>
              <p>ИНН: {provider_inn}</p>
              {provider_kpp && <p>КПП: {provider_inn}</p>}
              {provider_phone && <p>Телефон: {provider_phone}</p>}
            </div>

            <div className={cn(style.colTable, style.colTableLg)}>
              <h4 className={style.h4}>Реквизиты счёта:</h4>
              <p>Наименование банка получателя: {bank_name}</p>
              <p>Полное наименование получателя: {bank_fullname}</p>
              <p>ИНН: {bank_inn}</p>
              {bank_kpp && <p>КПП: {bank_kpp}</p>}
              <p>БИК: {bank_bik}</p>
              <p>Корреспондентский счет: {bank_correspondent_account}</p>
              <p>Расчётный счёт: {bank_checking_account}</p>
            </div>
          </div>
        </li>
      </ol>
    </>
  );
};

export default RenderRulesReservation;
