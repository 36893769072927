/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState, useRef } from 'react';
import { Form, Input } from 'antd/lib';
import InputMask from 'react-input-mask';

import { Select, Option } from 'azgaz-ui';
import { Car } from 'azgaz-connect';
import { config, applyOnSubmitHandler, terms } from '@/utils';
import { useCollapses } from '@/hooks';
import { formServiceRequest } from '@/api';
import type { TDealerOffice } from '@/types';

import 'antd/lib/date-picker/style/index.css';
import Message from '../Message';
import TermCheckbox from '../TermCheckbox';
import ButtonSubmit from '../ButtonSubmit';
import style from './style.module.scss';

type FormProps = {
  car: Car;
  dealerOffices: TDealerOffice[];
  onVisibleChange: (visible: boolean, callback?: Function) => void;
};

type inputValues = {
  [name: string]: any;
};
const phoneRegexp = /^\+\d \d\d\d \d\d\d-\d\d-\d\d$/;

const CarTestTruck: FC<FormProps> = ({
  onVisibleChange,
  car,
  dealerOffices,
}) => {
  const [form] = Form.useForm();
  const [formState, setFormSending] = useState({
    success: false,
    loading: false,
    error: false,
  });
  const hideFormTimeoutId = useRef<number>();

  const [termPersonalDataCollapse, termGettingInfoCollapse] = useCollapses([ false, false ]);

  const resetFields = () => {
    setFormSending({ success: false, loading: false, error: false });
    form.resetFields();
  };

  const hideForm = () => {
    onVisibleChange(false, () => clearTimeout(hideFormTimeoutId.current));

    // анимация закрытия длится 200ms
    setTimeout(() => {
      resetFields();
    }, 200);
  };

  const formSuccessMessage = () => {
    setFormSending({ success: true, loading: false, error: false });

    // по умолчанию форма закроется через 5 сек
    hideFormTimeoutId.current = setTimeout(() => {
      hideForm();
    }, 5000);
  };

  const parseValues = (values: inputValues) => {
    const { FIO, email, phoneNumber, dealerOffice, personalData, gettingInfo } = values;

    return {
      FIO,
      email,
      phoneNumber,
      dealerOffice,
      personalData,
      gettingInfo,
      carModel: car.grade,
      carName: car.model,
    };
  };

  const handleSubmit = async (values: inputValues) => {
    setFormSending({ success: false, loading: true, error: false });
    const parsedValues = parseValues(values);

    const resolvedValues = applyOnSubmitHandler(
      parsedValues,
      config.get('formOnSubmitTestTruck')
    );

    try {
      await formServiceRequest(
        config.get('formIdTestTruck'),
        resolvedValues
      );
      formSuccessMessage();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setFormSending({ success: false, loading: false, error: true });
    }
  };

  const onValuesChange = (changedValues: { [key: string]: any }) => {
    const { phone } = changedValues;
    if (phoneRegexp.test(phone)) {
      form.validateFields(['phoneNumber']);
    }
  };

  return (
    <Form
      form={form}
      name="test-truck"
      className={style.form}
      onValuesChange={onValuesChange}
      onFinish={handleSubmit}
    >
      <button type="button" onClick={hideForm} className={style.closeForm}>
        &#215;
      </button>
      {formState.error && (
        <Message type="error">К сожалению, отправка данных не удалась</Message>
      )}
      {formState.success ? (
        <Message type="success">
          Благодарим Вас за обращение! В ближайшее время наши специалисты
          свяжутся с Вами.
        </Message>
      ) : (
        <>
          <div className="row">
              <Form.Item
                className="col-12 mb-3"
                label="Как к вам обращаться*"
                name="FIO"
                rules={[
                  { required: true, message: 'Укажите ваш ФИО' },
                ]}
              >
                <Input
                  className="aui-input"
                  placeholder="Введите ФИО"
                />
              </Form.Item>
              <Form.Item
                className="col-12 mb-3"
                label="Ваш телефон*"
                name="phoneNumber"
                validateTrigger={['onBlur']}
                initialValue=""
                rules={[
                  { required: true, message: 'Укажите телефон' },
                  {
                    type: 'string',
                    pattern: phoneRegexp,
                    message: 'Укажите телефон',
                  },
                ]}
              >
                <InputMask
                  mask="+7 999 999-99-99"
                  placeholder="Без кода +7 или 8"
                  maskChar=""
                  type="tel"
                >
                  {(props: any) => <Input className="aui-input" {...props} />}
                </InputMask>
              </Form.Item>
              <Form.Item
                className="col-12 mb-3"
                label="Ваш e-mail"
                name="email"
                rules={[{ type: 'email', message: 'Неверный формат e-mail' }]}
              >
                <Input
                  className="aui-input"
                  type="email"
                  placeholder="example@example.ru"
                />
              </Form.Item>
          </div>
          {dealerOffices?.length > 1 ? (
            <Form.Item
              label="Выбор дилерского центра*"
              name="dealerOffice"
              className="mb-4"
              rules={[{ required: true, message: 'Выберите дилерский центр' }]}
            >
              <Select optionLabelProp="children">
                {dealerOffices.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            ) : null}
          <div className="mb-2">
            <TermCheckbox
              form={form}
              name="personalData"
              onToogleClick={termPersonalDataCollapse.toggle}
              termVisibility={termPersonalDataCollapse.visibility}
              title="Согласие на"
              toogleTitle="обработку персональных данных*"
              rules={[{
              validator: (_: any, value: boolean) =>
                value ? Promise.resolve() : Promise.reject(new Error('Обязательное поле')),
              }]}
              term={terms.personalData}
            />
          </div>
          <div className="mb-2">
            <TermCheckbox
              form={form}
              name="gettingInfo"
              onToogleClick={termGettingInfoCollapse.toggle}
              termVisibility={termGettingInfoCollapse.visibility}
              title="Согласие на"
              toogleTitle="получение информации"
              term={terms.gettingInfo}
            />
            </div>
            <ButtonSubmit
              text="заказать test-truck"
              loading={formState.loading}
            />
        </>
      )}
    </Form>
  );
};


export default CarTestTruck;
