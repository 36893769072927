import React, { FC } from 'react';
import { Car, useSelector, selectors } from 'azgaz-connect';
import { ExtraCarsList } from 'azgaz-ui';

import CarsItem from '../CarsItem';

type Props = {
  cars?: Car[];
};

const CarsRelated: FC<Props> = ({ cars }) => {
  const carsRelated = useSelector(selectors.getCarsRelated);
  const resolvedCars = cars?.length ? cars : carsRelated;

  return (
    <ExtraCarsList
      style={{
        backgroundColor: '#f2f0f0',
      }}
      title="Вас могут заинтересовать"
      Item={CarsItem}
      cars={resolvedCars}
    />
  );
};

export default CarsRelated;
