import React, { FC } from 'react';
import { CarsBar as CarsBarWrap, CarsBarFound, CarsBarSort } from 'azgaz-ui';
import { useSelector, selectors, useDispatch, actions } from 'azgaz-connect';
import { config } from '@/utils';
import style from './style.module.scss';
import CarsBarFavourites from '../CarsBarFavourites';

const CarsBar: FC = () => {
  const loading = useSelector(selectors.getCarsLoading);
  const count = useSelector(selectors.getCarsCount);
  const { sort } = useSelector(selectors.getResultParams);
  const dispatch = useDispatch();

  return (
    <CarsBarWrap>
      <div className={style.counts}>
        <CarsBarFound total={count.all} loading={loading} />
        {config.get('featureFlagFavourites') && <CarsBarFavourites />}
      </div>
      <CarsBarSort
        current={sort || '-state_active_at'}
        setSort={value => dispatch(actions.setSort(value))}
        options={[
          { id: 'price', label: 'Цене' },
          { id: 'state_active_at', label: 'Дате добавления' },
        ]}
      />
    </CarsBarWrap>
  );
};

export default CarsBar;
