import { useEffect, useRef } from 'react';
import * as queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Car } from '@azgaz/azgaz-ui/connect';

const useBookScrollByQueryParam = (
  car: Car | undefined,
  eventScrollToForms: CustomEvent
) => {
  const searchString = useLocation().search;
  const queryParams = queryString.parse(searchString);
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && car && queryParams.tobookform) {
      const formRect = formRef.current.getBoundingClientRect();
      // setTimeout - костыль, иначе не прокручивает до нужного места
      setTimeout(() => {
        document.dispatchEvent(eventScrollToForms);
        window.scrollTo({
          top: formRect.y,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [car]);

  return formRef;
};

export default useBookScrollByQueryParam;
