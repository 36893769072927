import React, { useState, useCallback, ReactNodeArray } from 'react';
import cn from 'classnames';

const useTabs = (
  { initTabIdx }: { initTabIdx: number },
  callback: () => void
) => {
  const [currentTab, setCurrentTab] = useState(initTabIdx);

  const renderTabs = (titles: ReactNodeArray) =>
    titles.map((title, idx) => (
      <button
        type="button"
        className={cn('aui-tabTitle', {
          'aui-tabTitle-active': idx === currentTab,
        })}
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        onClick={useCallback(() => {
          setCurrentTab(idx);
          if (typeof callback === 'function') {
            callback();
          }
        }, [])}
      >
        {title}
      </button>
    ));

  const renderContents = (contents: ReactNodeArray) =>
    contents.find((tab, idx) => idx === currentTab);

  return {
    renderTabs,
    renderContents,
    setCurrentTab,
    currentTab,
  };
};

export default useTabs;
