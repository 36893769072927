import React, { FC } from 'react';
import cn from 'classnames';
import { Button, Loader } from 'azgaz-ui';

import style from './style.module.scss';

type TProps = {
  text: string;
  className?: string;
  loading?: boolean;
};

const ButtonSubmit: FC<TProps> = ({ text, loading, className }) => {
  return (
    <Button
      className={cn(className, style.btn)}
      disabled={loading}
      type="submit"
    >
      {loading ? 'отправка данных' : text}
      {loading && (
        <Loader
          size={3}
          color="white"
          style={{ position: 'absolute', top: '6px', right: '9px' }}
        />
      )}
    </Button>
  );
};

export default ButtonSubmit;
