import React, { FC } from 'react';

import CarsList from '../CarsList';
import CarsBar from '../CarsBar';
import CarsLoadmore from '../CarsLoadmore';
// import CarsFallbackList from '../CarsFallbackList';
import LayoutMain from '../LayoutMain';
import outsideComponents from '../../outsideComponents';

const Cars: FC = () => {
  const { CarsFilters } = outsideComponents;

  return (
    <LayoutMain>
      {CarsFilters && <CarsFilters />}
      <CarsBar />
      <CarsList />
      {/* Скрыл список с запрос new авто - https://tracker.yandex.ru/RGB-841 */}
      {/* <CarsFallbackList /> */}
      <CarsLoadmore />
    </LayoutMain>
  );
};

export default Cars;
