import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import cn from 'classnames';

import { Car } from 'azgaz-connect';
import {
  Button,
  getSpecificationsForGroup,
  numberFormat,
  getCurrencySymbol,
} from 'azgaz-ui';

import getCarDescription from '@/utils/getCarDescription';
import { CarContext } from '../../contexts';

import style from './style.module.scss';

type TProps = {
  car: Car;
  btnClickHandler: () => void;
};

const CarHeaderFixed: FC<TProps> = ({ car, btnClickHandler }) => {
  const elCarInfoRef = useRef<HTMLElement | null>(null);
  const [visibility, setVisibility] = useState(false);
  const { carPriceWithDiscounts = 0, carPriceInfo } = useContext(CarContext);
  const price = carPriceInfo?.price || 0;
  const resolvedPrice = carPriceWithDiscounts || price;

  const priceDiffrence = carPriceWithDiscounts - price;
  const isShowOldPrice = priceDiffrence < 0;

  const specifications = getSpecificationsForGroup(car.specification, 'main', [
    'modification',
    'engine_type',
    'passangers',
    'chassis_length',
  ]);

  const title = `${car.model} ${car.name} ${specifications.modification || ''}`;
  const descr = getCarDescription(specifications);
  const currencySymbol = getCurrencySymbol(car.price.currency);

  const scrollHandler = useCallback(() => {
    const clientRect = elCarInfoRef.current?.getBoundingClientRect();

    if (!clientRect?.bottom) return;

    if (clientRect.bottom <= 0) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, []);

  useEffect(() => {
    elCarInfoRef.current = document.getElementById('info-properties');
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <div
      className={cn(style.wrap, 'azgazui__car-header-fixed', {
        [style.active]: visibility,
      })}
    >
      <div className="container">
        <div className="row flex-nowrap justify-content-between align-items-center">
          <div className="col-auto flex-shrink-1">
            <div className={style.carTitle}>{title}</div>
            <div className={style.carDescr}>
              {specifications.engine_type || ''}
              {descr.length !== 0 && <span>, {descr}</span>}
            </div>
          </div>
          <div className="col-auto d-flex flex-shrink-0">
            <div className="d-flex">
              {isShowOldPrice && (
                <div className="mr-4">
                  <div className={style.priceWithDiscount}>
                    {`${numberFormat()(price)} ${currencySymbol}`}
                  </div>
                  <div className={style.priceDiffrence}>{`${numberFormat()(
                    priceDiffrence
                  )} ${currencySymbol}`}</div>
                </div>
              )}
              <div className={style.price}>
                {`${numberFormat()(resolvedPrice)} ${currencySymbol}`}
              </div>
            </div>
            <div className="ml-5">
              <Button size="sm" onClick={btnClickHandler}>
                Забронировать
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarHeaderFixed;
