import { ComponentType } from 'react';
import { Car } from 'azgaz-connect';

export type Components = {
  CarTitle: ComponentType<{ car: Car }>;
  CarsFilters: ComponentType;
  CarsItemDealer: ComponentType<{ car: Car }>;
};

const outsideComponents: Partial<Components> = {};

export default outsideComponents;
