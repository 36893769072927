import React, { FC } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { utils } from 'azgaz-connect';
import { numberFormat } from 'azgaz-ui';
import { rubles } from 'rubles';

import testPromocode from '@/utils/testPromocode';
import { config } from '@/utils';

import { getOrderFullInfo } from '../../selectors';
import style from './style.module.scss';

type TOrderData = ReturnType<typeof getOrderFullInfo> & {
  dealerType?: 'manufacturer' | 'regular';
  promocode?: string,
};

const priceFormat = numberFormat({
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DEFAULT_MANUFACTURER_PREPAYMENT = 50000;

const Order: FC = () => {
  const location = useLocation();

  const orderData = utils.urlQueryString.parse(location.search) as TOrderData;

  const orderDate = orderData?.orderDate;
  const isCompanyMode = orderData?.userType === 'company';
  const isManufactureMode = orderData?.dealerType === 'manufacturer';
  let specifications;

  if (Array.isArray(orderData?.specifications)) {
    const booleanStrings = ['true', 'false'];
    specifications = orderData.specifications.filter(spec => !booleanStrings.includes(spec.toLowerCase())).join(', ');
  } else {
    specifications = orderData?.specifications;
  }

  const havePromocode = orderData?.promocode ? testPromocode(orderData.promocode) : false;

  let clientContent = null;

  if (orderData) {
    if (isCompanyMode) {
      clientContent = (
        <>
          {orderData.companyName}, {orderData.companyAddress}
          <br />
          ИНН: {orderData.inn}
          {orderData.kpp ? `, КПП: ${orderData.kpp}` : undefined}
          {orderData.companyPhone
            ? `, телефон: ${orderData.companyPhone}`
            : undefined}
        </>
      );
    } else {
      clientContent = (
        <>
          {orderData.userSurname} {orderData.userName}{' '}
          {orderData.userPatronymic}, телефон: {orderData.userPhone}, email:{' '}
          {orderData.userEmail}
        </>
      );
    }
  }

  const manufacturerPrepayment =
    orderData?.prepayment ?? DEFAULT_MANUFACTURER_PREPAYMENT;

  let basicPrice = Number(
    (isManufactureMode
      ? orderData?.carPriceInfoPrice
      : orderData?.prepayment) ?? 0
  );

  const basicPriceBeforePromocodeUsed = basicPrice;

  if (havePromocode) {
    basicPrice = basicPrice - config.get('promocodeDiscount');
  }

  const ndsPrice =
    basicPrice && orderData.carPriceInfoNds
      ? (Number(basicPrice) * Number(orderData.carPriceInfoNds)) /
        (100 + Number(orderData.carPriceInfoNds))
      : 0;

  const priceWithoutNds = Number(basicPrice) - Number(ndsPrice);
  const priceWithoutNdsBeforePromocodeUsed = Number(basicPriceBeforePromocodeUsed) - Number(ndsPrice)

  return orderData && orderDate ? (
    <div className={style.bill}>
      <table className={style.table1}>
        <tbody>
          <tr>
            <td colSpan={2} rowSpan={2} style={{ width: '46%' }}>
              <div className={style.name}>{orderData.bank_name}</div>
              <div className={style.label1}>Банк получателя</div>
            </td>
            <td>БИК</td>
            <td rowSpan={2} style={{ width: '46%' }}>
              {orderData.bank_bik}

              <div style={{ marginTop: 8 }}>
                {orderData.bank_checking_account}
              </div>
            </td>
          </tr>
          <tr>
            <td>Сч.&nbsp;&#8470;</td>
          </tr>
          <tr>
            <td>
              <span className={style.label2}>ИНН</span> {orderData.bank_inn}
            </td>
            <td>
              <span className={style.label2}>КПП</span> {orderData.bank_kpp}
            </td>
            <td rowSpan={2}>Сч.&nbsp;&#8470;</td>
            <td rowSpan={2}>{orderData.bank_correspondent_account}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className={style.name}>{orderData.bank_fullname}</div>
              <div className={style.label1}>Получатель</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={style.title}>
        Счет на оплату &#8470;{' '}
        <span className={style.title__num}>{orderData.orderNum}</span> от{' '}
        <span className={style.title__day}>{orderDate[0]}</span>.
        <span className={style.title__month}>{orderDate[1]}</span>.
        <span className={style.title__year}>{orderDate[2]}</span>
      </div>
      <table className={style.table2}>
        <tbody>
          <tr>
            <td>Поставщик</td>
            <td>
              {orderData.provider_name}, {orderData.provider_address}
              <br />
              ИНН: {orderData.provider_inn}{' '}
              {orderData.provider_kpp
                ? `, КПП: ${orderData.provider_kpp}`
                : undefined}
              {orderData.provider_phone
                ? `, телефоны: ${orderData.provider_phone}`
                : undefined}
            </td>
          </tr>
          <tr>
            <td>Грузоотправитель</td>
            <td>
              {orderData.consigner_name}, {orderData.consigner_address}
              <br />
              ИНН: {orderData.consigner_inn}
              {orderData.consigner_kpp
                ? `, КПП: ${orderData.consigner_kpp}`
                : undefined}
              {orderData.consigner_phone
                ? `, телефоны: ${orderData.consigner_phone}`
                : undefined}
            </td>
          </tr>
          <tr>
            <td>Покупатель</td>
            <td>{clientContent}</td>
          </tr>
          {!isManufactureMode && (
            <tr>
              <td>Основание</td>
              <td>Предварительная оплата (первый транш)</td>
            </tr>
          )}
        </tbody>
      </table>
      <table className={style.table3}>
        <tbody>
          <tr>
            <th className={style.nowrap}>&#8470;&nbsp;п/п</th>
            <th>
              Наименование Товара, его краткая характеристика, перечень работ,
              услуг
            </th>
            {isManufactureMode && (
              <>
                <th>Цена по договору, руб. без НДС</th>
                {havePromocode && (
                  <th>Цена с учетом промокода, руб. без НДС</th>
                )}
                <th>НДС</th>
              </>
            )}
            <th>Количество</th>
            <th>Ед. изм.</th>
            {isManufactureMode ? (
              <th>Сумма к оплате, руб с НДС</th>
            ) : (
              <>
                <th>Цена</th>
                <th>Сумма</th>
              </>
            )}
          </tr>
          <tr>
            <td>1.</td>
            <td>
              {!isManufactureMode && (
                <>Предварительная оплата (первый транш) за</>
              )}{' '}
              {orderData.family} {specifications}
            </td>
            {isManufactureMode && (
              <>
                <td className={cn(style['align-right'], style.nowrap)}>
                  {priceFormat(havePromocode ? priceWithoutNdsBeforePromocodeUsed : priceWithoutNds)}
                </td>
                {havePromocode && (
                  <td className={cn(style['align-right'], style.nowrap)}>
                    {priceFormat(priceWithoutNds)}
                  </td>
                )}
                <td className={cn(style['align-right'], style.nowrap)}>
                  {priceFormat(ndsPrice)}
                </td>
              </>
            )}
            <td className={style['align-right']}>1,000</td>
            <td>шт</td>
            {isManufactureMode ? (
              <td className={cn(style['align-right'], style.nowrap)}>
                {priceFormat(basicPrice)}
              </td>
            ) : (
              <>
                <td className={cn(style['align-right'], style.nowrap)}>
                  {priceFormat(basicPrice)}
                </td>
                <td className={cn(style['align-right'], style.nowrap)}>
                  {priceFormat(basicPrice)}
                </td>
              </>
            )}
          </tr>
        </tbody>
        <tfoot>
          {isManufactureMode ? (
            <>
              <tr>
                <td colSpan={havePromocode ? 4 : 3} />
                <td
                  colSpan={3}
                  className={cn(style['align-right'], style.nowrap)}
                >
                  Итого:
                </td>
                <td className={style.nowrap}>{priceFormat(basicPrice)}</td>
              </tr>
              <tr>
                <td colSpan={havePromocode ? 4 : 3} />
                <td
                  colSpan={3}
                  className={cn(style['align-right'], style.nowrap)}
                >
                  В том числе НДС:
                </td>
                <td className={style.nowrap}>{priceFormat(ndsPrice)}</td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td colSpan={4} />
                <td>Итого:</td>
                <td className={style.nowrap}>{priceFormat(basicPrice)}</td>
              </tr>
              <tr>
                <td colSpan={4} />
                <td>В том числе НДС:</td>
                <td className={style.nowrap}>{priceFormat(ndsPrice)}</td>
              </tr>
              <tr>
                <td colSpan={4} />
                <td>Всего к оплате:</td>
                <td className={cn(style.nowrap, style['total-price'])}>
                  {priceFormat(basicPrice)}
                </td>
              </tr>
            </>
          )}
        </tfoot>
      </table>
      {isManufactureMode ? (
        <>
          <div className={style['total-manufacturer-price']}>
            <strong>Сумма:</strong>{' '}
            {`${rubles(basicPrice)}, в т.ч. НДС ${priceFormat(ndsPrice)} руб.`}
          </div>
          <div className={style['title-manufacturer-disclaimer']}>
            <strong>Оплата товара осуществляется двумя траншами.</strong>
          </div>
          <div className={style['title-manufacturer-disclaimer']}>
            <strong>Оплата первого транша – </strong> в размере{' '}
            {priceFormat(manufacturerPrepayment)} руб., в течении 2–х рабочих с
            дней с момента получения счета.
          </div>
          <div className={style['title-manufacturer-disclaimer']}>
            <strong>Оплата второго транша – </strong> в размере{' '}
            {priceFormat(basicPrice - manufacturerPrepayment)} руб., (полной
            стоимости товара за вычетом суммы первого транша), в течении 3-х
            рабочих дней с момента получения сообщения о поступлении а/м на
            склад.
          </div>
        </>
      ) : (
        <>
          <div className={style['total-price-info']}>
            Всего по счету 1 наименований на общую сумму{' '}
            {priceFormat(basicPrice)} руб. ({rubles(basicPrice)})
          </div>
          {!!orderData.person_in_charge && (
            <div className={style['total-price-person']}>
              Ответственный:{' '}
              <strong>
                <i>{orderData.person_in_charge}</i>
              </strong>
            </div>
          )}
          {!!orderData.person_head_organization && (
            <div className={style['footer-person']}>
              <div className={style['footer-person__who']}>
                Руководитель организации
                <br />
                или иное уполномоченное лицо
              </div>
              <div className={style['footer-person__autograph']}>
                <div className={style['footer-person__field']} />
                <div className={style['footer-person__label']}>подпись</div>
              </div>
              <div className={style['footer-person__name']}>
                <div className={style['footer-person__field']}>
                  {orderData.person_head_organization}
                </div>
                <div className={style['footer-person__label']}>
                  расшифровка подписи
                </div>
              </div>
            </div>
          )}
          {!!orderData.signature_right_based && (
            <div className={style['footer-date']}>
              Право подписи на основании {orderData.signature_right_based}
            </div>
          )}
          <div>
            *стоимость на любые позиции товара не окончательная, и может быть
            изменена Продавцом в одностороннем порядке
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default Order;
