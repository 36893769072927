/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState, useRef, useContext, useEffect } from 'react';
import { Form, Radio, Input, DatePicker } from 'antd/lib';
import localeRU from '@/utils/calendarLocaleRU';
import InputMask from 'react-input-mask';
import moment from 'moment';
import {
  Select,
  Option,
  getSpecificationsForGroup,
  numberFormat,
} from 'azgaz-ui';
import { Car } from 'azgaz-connect';
import { config, applyOnSubmitHandler } from '@/utils';
import { formServiceRequest } from '@/api';

import skypeSvg from '@/assets/icons/skype.svg';
import zoomSvg from '@/assets/icons/zoom.svg';
import whatsappSvg from '@/assets/icons/whatsapp.svg';
import viberSvg from '@/assets/icons/viber.svg';
import facetimeSvg from '@/assets/icons/facetime.svg';

import type { TDealerOffice } from '@/types';
import { CarContext } from '../../contexts';

import 'antd/lib/date-picker/style/index.css';
import Message from '../Message';
import ButtonSubmit from '../ButtonSubmit';
import style from './style.module.scss';

type FormProps = {
  car: Car;
  dealerOffices: TDealerOffice[];
  onVisibleChange: (visible: boolean, callback?: Function) => void;
};

type inputValues = {
  [name: string]: any;
};
type Tmessenger = 'zoom' | 'whatsapp' | 'viber' | 'skype' | 'facetime';
const messengers: {
  icon: string;
  value: Tmessenger;
}[] = [
  {
    icon: zoomSvg,
    value: 'zoom',
  },
  {
    icon: whatsappSvg,
    value: 'whatsapp',
  },
  {
    icon: viberSvg,
    value: 'viber',
  },
  {
    icon: skypeSvg,
    value: 'skype',
  },
  {
    icon: facetimeSvg,
    value: 'facetime',
  },
];
const dateFormat = 'DD.MM.YYYY HH:mm';
const phoneRegexp = /^\+\d \d\d\d \d\d\d-\d\d-\d\d$/;

const minuteStep = 15;
const minutesRange = [0, 15, 30, 45, 60];

const resolveMinutesToAdd = (time: moment.Moment, minutes: number[]) => {
  const currMinutes = time.minutes();

  // вычисляем разницу в минутах из допустимого диапазона минут
  for (let i = 0; i < minutes.length; i += 1) {
    if (minutes[i] <= currMinutes && currMinutes < minutes[i + 1]) {
      return minutes[i + 1] - currMinutes;
    }
  }
  return 0;
};

const CarPresentation: FC<FormProps> = ({
  onVisibleChange,
  car,
  dealerOffices,
}) => {
  const [timeZone, setTimeZone] = useState(0);
  const [form] = Form.useForm();
  const [selectedMessenger, setMessenger] = useState<Tmessenger>('zoom');
  const carContext = useContext(CarContext);
  const [formState, setFormSending] = useState({
    success: false,
    loading: false,
    error: false,
  });
  const hideFormTimeoutId = useRef<number>();

  const resetFields = () => {
    setFormSending({ success: false, loading: false, error: false });
    setMessenger('zoom');
    form.resetFields();
  };

  const hideForm = () => {
    onVisibleChange(false, () => clearTimeout(hideFormTimeoutId.current));

    // анимация закрытия длится 200ms
    setTimeout(() => {
      resetFields();
    }, 200);
  };

  const formSuccessMessage = () => {
    setFormSending({ success: true, loading: false, error: false });

    // по умолчанию форма закроется через 5 сек
    hideFormTimeoutId.current = setTimeout(() => {
      hideForm();
    }, 5000);
  };

  const changeMessenger = (e: any) => {
    setMessenger(e.target.value);
  };

  const parseValues = (values: inputValues) => {
    const { phone, time, dealerOffice } = values;

    const mainSpecifications = getSpecificationsForGroup(
      car.specification,
      'main',
      ['modification', 'use', 'drive_type', 'engine_type', 'passangers']
    );

    const otherSpecifications = getSpecificationsForGroup(
      car?.specification,
      'other',
      ['dealer_comment']
    );

    return {
      phone,
      time: time.format(dateFormat),
      messenger: selectedMessenger,
      dealerOffice,
      vin: car.vin,
      carYear: car.year.toString(),
      carModel: car.grade,
      carName: car.model,
      carModification: mainSpecifications.modification,
      carUse: mainSpecifications.use,
      carDriveType: mainSpecifications.drive_type,
      carEngineType: mainSpecifications.engine_type,
      carPassengers: mainSpecifications.passangers?.toString(),
      carPrice: numberFormat({ style: 'currency', currency: 'RUB' })(
        carContext.carPriceInfo?.price || 0
      ),
      carDiscountPrice: numberFormat({ style: 'currency', currency: 'RUB' })(
        carContext.carPriceWithDiscounts || 0
      ),
      carDealerComment: otherSpecifications.dealer_comment,
    };
  };

  const handleSubmit = async (values: inputValues) => {
    setFormSending({ success: false, loading: true, error: false });
    const parsedValues = parseValues(values);

    const resolvedValues = applyOnSubmitHandler(
      parsedValues,
      config.get('formOnSubmitPresentation')
    );

    try {
      await formServiceRequest(
        config.get('formIdPresentation'),
        resolvedValues
      );
      formSuccessMessage();
      setTimeZone(0);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setFormSending({ success: false, loading: false, error: true });
    }
  };

  const getDisabledDate = (currentDate: any) => {
    return currentDate && currentDate < moment().startOf('day');
  };

  const getTimeZoneOffice = (officeId: string) => {
    const formDC = dealerOffices.find(({ id }) => id === officeId);
    const currOffice = car.dealer.offices.find((item: Record<string, any>) => item.name === formDC?.name);
    return currOffice?.timeZone;
  };

  const onChangeDealerOffice = (officeId: string) => {
    const timeZoneOffice = getTimeZoneOffice(officeId);
    setTimeZone(timeZoneOffice);
  };

  const onValuesChange = (changedValues: { [key: string]: any }) => {
    const { phone, dealerOffice } = changedValues;
    if (phoneRegexp.test(phone)) {
      form.validateFields(['phone']);
    }

    if (dealerOffice) {
      onChangeDealerOffice(dealerOffice);
    }
  };

  const onChangeTime = (selectedTime: moment.Moment | null) => {
    const nowTime = moment();
    const diffMinutes = selectedTime?.diff(nowTime, 'minutes');

    if (diffMinutes === undefined) {
      return;
    }

    // нельзя выбрать время меньше текущего
    if (diffMinutes <= 0) {
      const minutesToAdd = resolveMinutesToAdd(nowTime, minutesRange);

      form.setFieldsValue({
        time: moment(nowTime).add(minutesToAdd, 'minutes'),
      });
    }
  };

  // ограничить выбор времени с 9:00 до 19:00
  const disableHoursTime = () => [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    19,
    20,
    21,
    22,
    23,
  ];

  useEffect(() => {
    if (car.dealer.offices?.length === 1) {
      setTimeZone(car.dealer.offices[0].timeZone);
    }
  }, []);

  return (
    <Form
      form={form}
      name="online-presentation"
      className={style.form}
      onValuesChange={onValuesChange}
      onFinish={handleSubmit}
    >
      <button type="button" onClick={hideForm} className={style.closeForm}>
        &#215;
      </button>
      {formState.error && (
        <Message type="error">К сожалению, отправка данных не удалась</Message>
      )}
      {formState.success ? (
        <Message type="success">
          Благодарим Вас за обращение! В ближайшее время наши специалисты
          свяжутся с Вами.
        </Message>
      ) : (
        <>
          <div className={style.formRow}>
            <div>
              <Form.Item
                label="Ваш телефон*"
                name="phone"
                validateTrigger={['onBlur']}
                initialValue=""
                rules={[
                  { required: true, message: 'Укажите телефон' },
                  {
                    type: 'string',
                    pattern: phoneRegexp,
                    message: 'Укажите телефон',
                  },
                ]}
              >
                <InputMask
                  mask="+7 999 999-99-99"
                  placeholder="Без кода +7 или 8"
                  maskChar=""
                  type="tel"
                >
                  {(props: any) => <Input {...props} />}
                </InputMask>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Удобное время*"
                name="time"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: 'Укажите дату',
                  },
                ]}
              >
                <DatePicker
                  locale={localeRU}
                  format={dateFormat}
                  disabledHours={disableHoursTime}
                  minuteStep={minuteStep}
                  onChange={onChangeTime}
                  allowClear={false}
                  hideDisabledOptions
                  showTime
                  inputReadOnly
                  disabledDate={getDisabledDate}
                />
              </Form.Item>
            </div>
            {Boolean(timeZone) && (
              <span className={style.timeZoneTxt}>
                {
                  `* Внимание: при выборе времени используется часовой пояс дилера
                   (МСК ${timeZone > 0 ? '+' : ''}${timeZone})`
                }
              </span>
            )}
          </div>
          <div>
            <p>Выберите удобный Вам мессенджер для связи:</p>
            <Radio.Group onChange={changeMessenger} value={selectedMessenger}>
              {messengers.map(item => (
                <Radio key={item.value} value={item.value}>
                  <div className="radioIcon">
                    <img src={item.icon} alt="" />
                  </div>
                  {item.value}
                </Radio>
              ))}
            </Radio.Group>
          </div>
          {dealerOffices?.length ? (
            <Form.Item
              label="Выбор дилерского центра*"
              name="dealerOffice"
              className="mb-4"
              rules={[{ required: true, message: 'Выберите дилерский центр' }]}
            >
              <Select optionLabelProp="children">
                {dealerOffices.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            ) : null}
            <ButtonSubmit
              text="заказать презентацию"
              loading={formState.loading}
            />
        </>
      )}
    </Form>
  );
};

export default CarPresentation;
