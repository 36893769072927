import { maskPattern } from '../apps/appCreator/components/InputPhone';

export default {
  required: { required: true, message: 'Обязательное поле' },
  fullName: {
    pattern: /[a-zA-Zа-яА-Я]+/,
    message: 'Укажите Ваше ФИО',
  },
  phone: {
    pattern: maskPattern,
    message: 'Укажите телефон в формате +7 123 456-78-90',
  },
  email: { type: 'email', message: 'Неверный формат e-mail' },
  minLength: (len: number) => (rule: any, value: string) => {
    const errorText = `Поле должно содержать минимум ${len} символа`;
    return value.length < len ? [errorText] : [];
  },
  len: (len: number) => ({
    len,
    message: `Длина должна быть ровно ${len} цифр`,
  }),
  lenOrOr: (lengths: number[]) => (rule: any, value: string) => {
    let errorsCount = 0;
    const errorText = `Длина должна быть ${lengths.join(' или ')} цифр`;

    lengths.forEach(len => {
      if (value.length !== len) {
        errorsCount += 1;
      }
    });

    return errorsCount === lengths.length ? [errorText] : [];
  },
  term: (rule: any, value: boolean) => {
    return value !== true ? ['Обязательное поле'] : [];
  },
};
