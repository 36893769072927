import React, { FC } from 'react';
import { getSpecificationsForGroup } from 'azgaz-ui';
import { Car } from 'azgaz-connect';
import { getCarPriceInfo } from '../../selectors';
import style from './style.module.scss';

type CarDisclaimerProps = {
  car: Car | undefined;
};

const CarDisclaimer: FC<CarDisclaimerProps> = ({ car }) => {
  const creditPrice = car?.user_metadata?.credit_price;

  const creditParams = getSpecificationsForGroup(
    car?.specification,
    'credit-params',
    ['init-payment', 'period']
  );

  const isShowCreditDisclaimer =
    creditPrice && Object.keys(creditParams).length === 2;

  const leasingPrice = car?.price?.leasing_price;

  const isShowLeasingDisclaimer =
    leasingPrice !== undefined && leasingPrice > 0;

  const carPriceInfo = getCarPriceInfo(car);

  return (
    <ul className={style.list}>
      {/* TODO: Временно скрыто
        {isShowCreditDisclaimer ? (
          <li className={style.item}>
            * Указанный размер ежемесячного платежа по кредиту складывается при
            первоначальном взносе в размере {creditParams['init-payment']} руб.,
            срок кредитования {creditParams.period} мес. Решение о выдаче кредита
            принимается банком-партнером. Подробности об условиях кредита и
            банках-партнерах уточняйте по телефонам в разделе
            &quot;Контакты&quot;. Не является публичной офертой.
          </li>
        ) : (
          Boolean(creditPrice) && (
            <li className={style.item}>
              * Решение о выдаче кредита принимается банком-партнером. Подробности
              об условиях кредита и банках-партнерах уточняйте по телефонам в
              разделе &quot;Контакты&quot;. Не является публичной офертой.
            </li>
          )
        )}
        {isShowLeasingDisclaimer && (
          <li className={style.item}>
            * Размер ежемесячного платежа в лизинг складывается при фиксированных
            первоначальном взносе и сроке лизинга. Требования, предъявляемые к
            заемщику, определяются лизинговой компанией-партнером. Подробности об
            условиях лизинга, размере первоначального взноса, обязательных
            платежах и лизинговых партнерах уточняйте по телефонам в разделе
            &quot;Контакты&quot;. Не является публичной офертой.
          </li>
        )} 
      */}
      {[
        ...(carPriceInfo.tradeIn
          ? [
              <>
                <sup>{carPriceInfo.disclaimers.order.indexOf('tradeIn')}</sup>{' '}
                {carPriceInfo.disclaimers.tradeInDisclaimer?.trim() ||
                  `«Фирменная программа обновления 2020» - скидка в
                размере 175 тыс. руб. предоставляется Клиенту, сдающему по схеме
                Trade-in автомобиль не старше 2010 года выпуска, который был во
                владении не менее 1 года, скидка по данной программе
                предоставляется в случае приобретения Клиентом у Дилера
                сервисного контракта (минимум 2 тех. обслуживания). Программа
                действует до 30.09.2020г. или до исчерпания бюджета на их
                проведение. Не является публичной офертой. Подробности по
                телефонам, указанным в разделе "Контакты".`}
              </>,
            ]
          : []),
        ...(carPriceInfo.credit
          ? [
              <>
                <sup>{carPriceInfo.disclaimers.order.indexOf('credit')}</sup>{' '}
                {carPriceInfo.disclaimers.creditDisclaimer?.trim() ||
                  `По «Фирменной кредитной программе» выгода в размере
                6 % предоставляется при приобретении автомобиля с помощью
                целевого автокредита с остаточным платежом по программе
                «Buy-Back» («Обратный выкуп»), предоставляемого АО «Совкомбанк».
                Кредитная программа с остаточным платежом с АО «Совкомбанк»
                (Генеральная лицензия Банка России № 963): срок кредитования
                12–60 мес. и первоначальный взнос от 20 % от стоимости
                автомобиля. Размер последнего, выкупного платежа по кредиту
                составит до 40 % от стоимости автомобиля. Обязательно оформление
                КАСКО. Решение о выдаче кредита принимается банком-партнером.
                Программы действуют до 30.09.2020 или до исчерпания бюджета на
                их проведение. Не является публичной офертой. Подробности — в
                Дилерских центрах «ГАЗ».`}
              </>,
            ]
          : []),
        ...(carPriceInfo.leasing
          ? [
              <>
                <sup>{carPriceInfo.disclaimers.order.indexOf('leasing')}</sup>{' '}
                {carPriceInfo.disclaimers.leasingDisclaimer?.trim() ||
                  `По государственной программе льготного лизинга
                Клиенту лизинговой компанией предоставляется скидка в размере
                10% от стоимости автомобиля с учетом всех иных скидок и
                возможной доработки/тюнинга. Программа действует на автомобили,
                выпущенные не ранее 01.01.2020г.; по программе «Свое
                дело» скидка в размере 2,5% от стоимости автомобиля
                предоставляется Клиенту лизинговой компанией. Клиент должен быть
                включен в Единый реестр субъектов малого и среднего
                предпринимательства. Программа действует на автомобили,
                выпущенные не ранее 01.01.2020г. Программы действуют до
                30.09.2020г. или до исчерпания бюджета на их проведение. Не
                является публичной офертой. Подробности по телефонам, указанным
                в разделе "Контакты".`}
              </>,
            ]
          : []),
      ].map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={style.item} key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default CarDisclaimer;
