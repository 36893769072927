import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Cars from '../Cars';
import Car from '../Car';
import Order from '../Order';
import Favourites from '../Favourites';

export default hot(() => (
  <Switch>
    <Route path="/order">
      <Order />
    </Route>
    <Route path="/favourites">
      <Favourites />
    </Route>
    <Route path="/:id">
      <Car />
    </Route>
    <Route path="/">
      <Cars />
    </Route>
  </Switch>
));
