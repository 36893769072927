import objectHash from 'object-hash';

type Opts = {
  exclude?: string[];
};

export default (obj: { [key: string]: any }, opts: Opts = {}) => {
  const { exclude } = opts;
  const hash = objectHash(obj, {
    excludeKeys: key => {
      if (exclude && exclude.indexOf(key) !== -1) {
        return true;
      }
      return false;
    },
  });

  const orderNum = hash.split('').filter((value: string) => Number(value));

  return orderNum.slice(0, 10).join('');
};
