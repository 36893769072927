import { useState, useEffect } from 'react';
import { config } from '@/utils';
import { getDealerOffices } from '@/api';
import type { TDealerOffice} from '@/types';

// Данные для поля "дилерские центры" в формах: онлайн презентация, забронировать автомобиль, счет на оплату
export default function useDealerOffices() {
  const [dealerOffices, setDealerOffices] = useState<TDealerOffice[]>([]);

  useEffect(() => {
    const officesDatasourceId = config.get('officesDatasourceId');

    if (officesDatasourceId) {
      getDealerOffices(officesDatasourceId)
        .then(res => {
          setDealerOffices(res.data);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    }
  }, []);

  return {
    dealerOffices,
  };
}
