import React, { FC, useContext } from 'react';
import { CarInfoPrice as AuiCarInfoPrice } from 'azgaz-ui';
import { CarContext } from '../../contexts';
import CarDiscount from '../CarDiscount';

const CarInfoPrice: FC = () => {
  const { carPriceWithDiscounts, carPriceInfo } = useContext(CarContext);
  const priceWithDiscounts = carPriceWithDiscounts || 0;
  const price = carPriceInfo?.price || 0;
  const isShowOldPrice = price - priceWithDiscounts > 0;

  return price ? (
    <AuiCarInfoPrice
      price={priceWithDiscounts}
      oldPrice={isShowOldPrice ? price : undefined}
      discount={<CarDiscount />}
    />
  ) : null;
};

export default CarInfoPrice;
