/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/brace-style */
import React, { FC, useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import debounce from 'debounce';
import * as rcFormUtils from 'rc-form/lib/utils';
import { Input, Textarea, Select, Option } from 'azgaz-ui';
import {
  useSelector as useSelectorConnect,
  selectors as selectorsConnect,
} from 'azgaz-connect';
import cn from 'classnames';

// eslint-disable-next-line import/named
import { dadataApi } from '@/api';
import { useFormHandlers, useCollapses } from '@/hooks';
import { rules, terms } from '@/utils';
import { TDealerOffice } from '@/types';
import { useSuggestions, suggestionsActions, useOfferCollapse } from './hooks';
import { fields, orderActions, userTypes } from '../../store/order';
import {
  getOrder,
  getDealerOfficesActiveRequisites,
  getDealerCurrentOffice,
} from '../../selectors';
import { Office } from '../../selectors/getDealerOffices';
import Collapse, { Panel } from '../Collapse';
import CarRulesReservation from '../CarRulesReservation';
import InputPhone from '../InputPhone';
import Popover from '../Popover';
import TermCheckbox from '../TermCheckbox';
import Message from '../Message';
import CarOrderHint from '../CarOrderHint';
import ScrollBar from '../ScrollBar';
import Radio, { Group } from '../Radio';
import ButtonSubmit from '../ButtonSubmit';
import style from './style.module.scss';
import { State } from '../../store';

type CarOrderProps = {
  form: any;
  dealerOffices: TDealerOffice[];
};

const CarOrder: FC<CarOrderProps> = ({ form, dealerOffices }) => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const [companyNameShow, setCompanyNameShow] = useState(false);
  const dispatch = useDispatch();
  const companyNameRef = useRef<any>();
  const { suggestions, dispatchSuggestions, hasSuggestions } = useSuggestions();
  const car = useSelectorConnect(selectorsConnect.getCar);
  const officesWithActiveRequisites = getDealerOfficesActiveRequisites(car);
  const isCompanyMode = getFieldValue(fields.userType) === userTypes.company;
  const contractOfferField = isCompanyMode
    ? 'contract_offer'
    : 'contract_offer_personal';

  const currentOffice = useSelector((state: State) =>
    getDealerCurrentOffice(state, car)
  );

  const currenDatasourceDealer = dealerOffices.find(
    dsOffice => dsOffice.name === currentOffice?.name
  );

  const { loading, error, formSuccessfullySent } = useSelector(getOrder);

  const {
    contractOfferCollapseId,
    offersCollapse,
    setOffersCollapse,
    scrollToOffer,
  } = useOfferCollapse();

  const { onSubmit, getError } = useFormHandlers({
    form,
    submitAction: () =>
      dispatch(orderActions.createOrder(car, currenDatasourceDealer?.id)),
  });

  const [
    termPersonalDataCollapse,
    termGettingInfoCollapse,
    termRulesInfoCollapse,
  ] = useCollapses([false, false, false]);

  const selectCompanyName = useCallback(
    hid => {
      setCompanyNameShow(false);

      const currentCompany = suggestions.list.find(
        suggest => suggest.data.hid === hid
      );

      // проставляем остальные значения
      if (currentCompany) {
        setFieldsValue({
          [fields.companyName]:
            currentCompany.data.name.short_with_opf ||
            currentCompany.data.name.full_with_opf,
          [fields.companyAddress]:
            currentCompany.data.address.unrestricted_value || '',
          [fields.kpp]: currentCompany?.data.kpp || '',
          [fields.inn]: currentCompany?.data.inn || '',
        });
      }
    },
    [suggestions.list]
  );

  const changeCompanyName = useCallback(
    debounce(async (name: any) => {
      // hid - строка длиной 64 символа без пробелов
      const isHid = /\S{64}/gm.test(name);

      // !!!Hacked: при событии select в name хранится hid
      if (!isHid) {
        dispatchSuggestions(suggestionsActions.start('byName'));

        try {
          const suggestionsResponce = await dadataApi.suggestParty(name);

          dispatchSuggestions(
            suggestionsActions.success(suggestionsResponce.data.suggestions)
          );

          // Показываем возможные значения
          if (suggestionsResponce.data.suggestions.length) {
            setCompanyNameShow(true);
          }
        } catch (e) {
          dispatchSuggestions(suggestionsActions.error(e.toString()));
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }, 500),
    [suggestions.list]
  );

  const getDealerOfficesField = (offices: Office[]) => {
    return getFieldDecorator(fields.dealerOfficeId)(
      <Select title="Выбор дилерского центра*" optionLabelProp="children">
        {offices.map(office => (
          <Option value={office.id} key={office.id}>
            {office.name}
          </Option>
        ))}
      </Select>
    );
  };

  useEffect(() => {
    if (officesWithActiveRequisites?.length) {
      setFieldsValue({
        [fields.dealerOfficeId]: officesWithActiveRequisites[0].id,
      });
    }
  }, []);

  // смена юр/физ лицо
  useEffect(() => {
    dispatch(orderActions.clearFormMessage());
  }, [isCompanyMode]);

  return officesWithActiveRequisites?.length ? (
    <form onSubmit={onSubmit} noValidate>
      <div className="row mb-4">
        <div className="col-md-6">
          {getFieldDecorator(fields.userType)(
            <Group>
              <Radio value={userTypes.company}>Юридическое лицо / ИП</Radio>
              <Radio value={userTypes.personal}>Физическое лицо</Radio>
            </Group>
          )}
        </div>
        <div className="col-md-6">
          <CarOrderHint />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className={style.title}>Контактная информация</div>
        </div>
      </div>
      <div className="row mb-4">
        {isCompanyMode ? (
          <div className="col-md-4 mb-4">
            {getFieldDecorator(fields.userFIO, {
              rules: [rules.required, rules.fullName],
            })(
              <Input
                title="ФИО*"
                placeholder="Введите ФИО"
                error={getError(fields.userFIO)}
              />
            )}
          </div>
        ) : (
          <>
            <div className="col-md-4 mb-4">
              {getFieldDecorator(fields.userSurname, {
                rules: [rules.required, rules.fullName, rules.minLength(3)],
              })(
                <Input
                  title="Фамилия*"
                  placeholder="Введите Фамилию"
                  error={getError(fields.userSurname)}
                />
              )}
            </div>
            <div className="col-md-4 mb-4">
              {getFieldDecorator(fields.userName, {
                rules: [rules.required, rules.fullName, rules.minLength(3)],
              })(
                <Input
                  title="Имя*"
                  placeholder="Введите Имя"
                  error={getError(fields.userName)}
                />
              )}
            </div>
            <div className="col-md-4 mb-4">
              {getFieldDecorator(fields.userPatronymic, {
                rules: [rules.required, rules.fullName, rules.minLength(3)],
              })(
                <Input
                  title="Отчество*"
                  placeholder="Введите Отчество"
                  error={getError(fields.userPatronymic)}
                />
              )}
            </div>
          </>
        )}

        <div className="col-md-4 mb-4">
          {getFieldDecorator(fields.userPhone, {
            rules: [rules.required, rules.phone],
          })(
            <InputPhone title="Телефон*" error={getError(fields.userPhone)} />
          )}
        </div>
        <div className="col-md-4 mb-4">
          {getFieldDecorator(fields.userEmail, {
            rules: [rules.required, rules.email],
          })(
            <Input
              title="E-mail*"
              type="email"
              placeholder="name@domain.com"
              error={getError(fields.userEmail)}
            />
          )}
        </div>
        <div
          className="col-md-4 mb-4"
          style={{
            ...((isCompanyMode || officesWithActiveRequisites.length <= 1) && {
              display: 'none',
            }),
          }}
        >
          {getDealerOfficesField(officesWithActiveRequisites)}
        </div>
        <div className="col-12">
          {getFieldDecorator(fields.userComment)(
            <Textarea title="Комментарий" />
          )}
        </div>
      </div>

      <div
        className={cn({
          'd-none': !isCompanyMode,
        })}
      >
        <div className={style.title}>Данные для счёта</div>
        <div className="row">
          <div className="col-md-8 mb-4">
            {getFieldDecorator(fields.companyName, {
              rules: isCompanyMode ? [rules.required] : [],
            })(
              <Select
                combobox
                open={companyNameShow}
                ref={companyNameRef}
                className={style.select}
                showArrow={hasSuggestions}
                notFoundContent={false}
                optionFilterProp="children"
                title="Полное наименование организации*"
                placeholder="Введите наименование организации"
                error={getError(fields.companyName)}
                onFocus={useCallback(() => setCompanyNameShow(true), [])}
                onBlur={useCallback(() => setCompanyNameShow(false), [])}
                onSelect={selectCompanyName}
                onChange={changeCompanyName}
                filterOption={false} // Фильтрация происходит на уровне внешнего dadata api
              >
                {suggestions.list
                  .map(suggest => suggest.data)
                  .map(({ name, hid }) => (
                    <Option key={hid} value={hid}>
                      {name.short_with_opf || name.full_with_opf}
                    </Option>
                  ))}
              </Select>
            )}
          </div>
          <div className="col-md-4 mb-4">
            {getFieldDecorator(fields.inn, {
              rules: isCompanyMode
                ? [(rules.required, rules.lenOrOr([10, 12]))]
                : [],
            })(
              <InputMask
                mask="999999999999"
                maskChar=""
                onChange={useCallback(async event => {
                  const validator = rules.lenOrOr([10, 12]);
                  const inn = rcFormUtils.getValueFromEvent(event);
                  const isHasError = validator(null, inn).length !== 0;
                  const isSameValue = inn === getFieldValue(fields.inn);

                  if (isHasError || isSameValue) return;

                  try {
                    dispatchSuggestions(suggestionsActions.start('byInn'));

                    const suggestionsResponce = await dadataApi.findByIdParty(
                      inn
                    );

                    dispatchSuggestions(
                      suggestionsActions.success(
                        suggestionsResponce.data.suggestions
                      )
                    );

                    // Показываем возможные значения
                    if (
                      suggestionsResponce.data.suggestions.length > 1 &&
                      companyNameRef
                    ) {
                      companyNameRef.current?.focus();
                      setFieldsValue({
                        [fields.companyName]: '',
                      });
                    }
                    // Выбираем единственно возможное значение
                    else if (
                      suggestionsResponce.data.suggestions.length === 1
                    ) {
                      const [
                        currentCompany,
                      ] = suggestionsResponce.data.suggestions;

                      setFieldsValue({
                        [fields.companyName]:
                          currentCompany.data.name.short_with_opf || '',
                        [fields.companyAddress]:
                          currentCompany.data.address.unrestricted_value || '',
                        [fields.kpp]: currentCompany.data.kpp || '',
                      });
                    }
                  } catch (e) {
                    dispatchSuggestions(suggestionsActions.error(e.toString()));
                    // eslint-disable-next-line no-console
                    console.error(e);
                  }
                }, [])}
              >
                {(rest: any) => (
                  <Input
                    type="tel" // с number не работает маска
                    placeholder="000000000000"
                    error={getError(fields.inn)}
                    loading={suggestions.loading.byInn}
                    title={
                      <div className={style.innTitle}>
                        ИНН*
                        <Popover
                          trigger="hover"
                          placement="topRight"
                          arrowPointAtCenter
                          overlayClassName={style.innHintPopover}
                          content={
                            <span className={style.innHintText}>
                              Введите ИНН для автоматического заполнения
                              информации об организации, которую потом вы можете
                              скорректировать
                            </span>
                          }
                        >
                          <span className={style.innHint} />
                        </Popover>
                      </div>
                    }
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                  />
                )}
              </InputMask>
            )}
          </div>
          <div className="col-md-8 mb-4">
            {getFieldDecorator(fields.companyAddress, {
              rules: isCompanyMode ? [rules.required] : [],
            })(
              <Input
                title="Юридический адрес*"
                placeholder="Введите юридический адрес"
                error={getError(fields.companyAddress)}
              />
            )}
          </div>
          <div className="col-md-4 mb-4">
            {getFieldDecorator(fields.kpp, {
              rules: [rules.len(9)],
            })(
              <InputMask mask="999999999" maskChar="">
                {(rest: any) => (
                  <Input
                    type="tel"
                    title="КПП"
                    placeholder="000000000"
                    error={getError(fields.kpp)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                  />
                )}
              </InputMask>
            )}
          </div>
          <div className="col-md-4 mb-4">
            {getFieldDecorator(fields.companyPhone, {
              rules: [rules.phone],
            })(
              <InputPhone
                title="Телефон организации"
                error={getError(fields.companyPhone)}
              />
            )}
          </div>
          <div
            className="col-md-4 mb-4"
            style={{
              ...(officesWithActiveRequisites.length <= 1 && {
                display: 'none',
              }),
            }}
          >
            {getDealerOfficesField(officesWithActiveRequisites)}
          </div>
        </div>
      </div>

      <div className="row mb-5">
        {currentOffice?.requisites?.[contractOfferField] && (
          <div className="col-md-6 mb-4">
            <TermCheckbox
              error={getError(fields.termOrderAgreement)}
              form={form}
              name={fields.termOrderAgreement}
              onToogleClick={scrollToOffer}
              title="Согласие c"
              toogleTitle="договором оферты*"
              rules={[rules.term]}
            />
          </div>
        )}
        <div className="col-md-6 mb-4">
          <TermCheckbox
            error={getError(fields.termPersonalData)}
            form={form}
            name={fields.termPersonalData}
            onToogleClick={termPersonalDataCollapse.toggle}
            termVisibility={termPersonalDataCollapse.visibility}
            title="Согласие на"
            toogleTitle="обработку персональных данных*"
            rules={[rules.term]}
            term={terms.personalData}
          />
        </div>
        <div className="col-md-6 mb-4">
          <TermCheckbox
            form={form}
            name={fields.termGettingInfo}
            onToogleClick={termGettingInfoCollapse.toggle}
            termVisibility={termGettingInfoCollapse.visibility}
            title="Согласие на"
            toogleTitle="получение информации"
            term={terms.gettingInfo}
          />
        </div>
        <div className="col-md-6 mb-4">
          <TermCheckbox
            form={form}
            name={fields.termRulesInfo}
            onToogleClick={termRulesInfoCollapse.toggle}
            termVisibility={termRulesInfoCollapse.visibility}
            title="Согласие с"
            toogleTitle="Правилами оказания услуг по резервированию товаров на сайте"
            modalMode
            term={<CarRulesReservation car={car} />}
          />
        </div>
        {formSuccessfullySent && (
          <div className={cn(style.success, 'col-12', 'mb-4')}>
            Заявка успешно отправлена!
          </div>
        )}
        <div className="col-12">
          <ButtonSubmit text="Получить счет" loading={loading.common} />
        </div>
        {(error.fs || error.pdf) && (
          <div className="col-12 mt-4">
            <Message type="error">
              {Object.values(error).map((e, idx, all) => (
                <>
                  {e}
                  {all.length - 1 > idx && <br />}
                </>
              ))}
            </Message>
          </div>
        )}
      </div>
      {currentOffice?.requisites?.[contractOfferField] && (
        <div id={contractOfferCollapseId}>
          <Collapse
            activeKey={offersCollapse}
            onChange={setOffersCollapse}
            className={style.collapse}
          >
            <Panel header="Договор оферты" key="0" className={style.panel}>
              <ScrollBar>
                <div
                  dangerouslySetInnerHTML={{
                    __html: currentOffice.requisites[contractOfferField],
                  }}
                />
              </ScrollBar>
            </Panel>
          </Collapse>
        </div>
      )}
      <div className={style.disclaimer}>
        *стоимость на любые позиции товара не окончательная, и может быть
        изменена Продавцом в одностороннем порядке
      </div>
    </form>
  ) : null;
};

export default CarOrder;
