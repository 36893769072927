import React, { FC } from 'react';
import { hooks, Car } from 'azgaz-connect';
import { ExtraCarsList } from 'azgaz-ui';
import CarsItem from '../CarsItem';

import style from './style.module.scss';

type CarsSeenProps = {
  car: Car | undefined;
};

const CarsSeen: FC<CarsSeenProps> = ({ car }) => {
  const { carsSeenForCurrentCar } = hooks.useSeens(car);

  return (
    <ExtraCarsList
      className={style.carsList}
      title="Вы недавно смотрели"
      Item={CarsItem}
      cars={carsSeenForCurrentCar}
    />
  );
};

export default CarsSeen;
