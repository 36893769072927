import React, { FC, useState, useCallback } from 'react';
import { Car } from 'azgaz-connect';
import { Button } from 'azgaz-ui';

import type { TDealerOffice } from '@/types';
import CarTestTruck from '../CarTestTruck';
import Popover from '../Popover';

type TProps = {
  car: Car;
  dealerOffices: TDealerOffice[];
};

const CarTestTruckLink: FC<TProps> = ({ car, dealerOffices }) => {
  const [visibleForm, toggleForm] = useState(false);

  const toggleVisible = useCallback((visible: boolean, callback?: Function) => {
    toggleForm(visible);

    if (callback) {
      callback();
    }
  }, []);

  return <Popover
    placement="bottom"
    title="Выездной TEST-TRUCK"
    trigger="click"
    visible={visibleForm}
    onVisibleChange={toggleVisible}
    content={
      <CarTestTruck
        car={car}
        dealerOffices={dealerOffices}
        onVisibleChange={toggleVisible}
      />
    }
  >
    <Button size="sm" transparent>выездной test-truck</Button>
  </Popover>;
};

export default CarTestTruckLink;
