/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { config } from '@/utils';

export const api = axios.create({
  baseURL: config.get('dadataApi'),
  headers: {
    Authorization: `Token ${config.get('dadataApiKey')}`,
  },
});

export type TSuggestion = {
  value: string;
  data: {
    name: {
      full_with_opf: string;
      short_with_opf: string;
    };
    hid: string;
    inn: string;
    kpp?: string;
    address: {
      unrestricted_value: string;
    };
  };
};

let suggestionsCancel: () => void | undefined;

export function suggestParty(name: any) {
  if (suggestionsCancel) {
    suggestionsCancel();
  }

  return api.post<{
    suggestions: TSuggestion[];
  }>(
    '/suggest/party',
    {
      query: name,
    },
    {
      cancelToken: new axios.CancelToken(c => {
        suggestionsCancel = c;
      }),
    }
  );
}

export function findByIdParty(inn: any) {
  if (suggestionsCancel) {
    suggestionsCancel();
  }

  return api.post<{
    suggestions: TSuggestion[];
  }>(
    '/findById/party',
    {
      query: inn,
    },
    {
      cancelToken: new axios.CancelToken(c => {
        suggestionsCancel = c;
      }),
    }
  );
}
