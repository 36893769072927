/* eslint-disable consistent-return */
import { createSelector } from '@reduxjs/toolkit';
import { Car } from 'azgaz-connect';
import getOrderFields from './getOrderFields';
import { State } from '../store';
import getDealerOfficesActiveRequisites from './getDealerOfficesActiveRequisites';

export default createSelector(
  getOrderFields,
  (state: State, car: Car | undefined) => getDealerOfficesActiveRequisites(car),
  (orderFields, offices) => {
    if (!offices?.length) return;

    return offices?.find(office => office.id === orderFields.dealerOfficeId);
  }
);
