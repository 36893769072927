/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState } from 'react';

import { useSelector, selectors } from 'azgaz-connect';
import { vehiclesList } from '@/api';
import { config } from '@/utils';
import CarsRelated from '../CarsRelated';

const pagePathname = window.location.pathname;

export const PagePathnameContext = React.createContext('');

const CarsFallbackList: FC = () => {
  const cars = useSelector(selectors.getCars);
  const [carsRelated, setCarsRelated] = useState([]);
  const isCarGroupsUsed = config
    .get('carGroups')
    .find(({ id }: { id: string }) => id === 'used');

  /**
   * В случае если новые автомобили загружены в виджет, а б/у нет,
   * то у б/у авто выводим "Вас могут заинтересовать" с рандомными новыми авто дилера
   */
  const isShowCarsRelated = isCarGroupsUsed && cars && cars.length === 0;

  useEffect(() => {
    if (isShowCarsRelated) {
      vehiclesList('group=["new"]&hidden=false&state=active&per_page=3').then(
        res => {
          setCarsRelated(res.data);
        }
      );
    }
  }, [cars]);

  if (!isShowCarsRelated) {
    return null;
  }

  return (
    <PagePathnameContext.Provider value={pagePathname.replace('used', 'new')}>
      <CarsRelated cars={carsRelated} />
    </PagePathnameContext.Provider>
  );
};

export default CarsFallbackList;
