import { utils } from 'azgaz-connect';
import { config } from '@/utils';

const filtersConfig = utils.UiHelper.patchFiltersConfig({
  model: {
    id: 'model',
    path: 'model',
  },
  grade: {
    id: 'grade',
    path: 'grade',
  },
  use: {
    id: 'use',
    path: 'specification.use',
  },
  driveType: {
    id: 'drive_type',
    path: 'specification.drive_type',
  },
  passangers: {
    id: 'passangers',
    path: 'specification.passangers',
  },
  engineType: {
    id: 'engine_type',
    path: 'specification.engine_type',
  },
  year: {
    id: 'year',
    path: 'year',
  },
  price: {
    id: 'price',
    path: 'price.value.0.0',
    defaultVal: [1, null],
  },
  state: {
    id: 'state',
    defaultVal: 'active',
  },
  priceTier: {
    id: 'price_tier',
    defaultVal: 0,
  },
  discountTier: {
    id: 'discount_tier',
    defaultVal: 0,
  },
  dealer: {
    id: 'dealer',
    defaultVal: config.get('dealer'),
  },
  group: {
    id: 'group',
    defaultVal: config.get('carGroups').map(({ id }: { id: string }) => id),
  },
  // brand: {
  //   id: 'brand',
  //   defaultVal: 'azgaz',
  // },
});

export default filtersConfig;
