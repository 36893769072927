import React, {
  ComponentProps,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import InputMask from 'react-input-mask';
import { Input } from 'azgaz-ui';

type InputPhoneProps = Omit<ComponentProps<typeof InputMask>, 'mask'> &
  ComponentProps<typeof Input>;

export const maskPattern = /^\+\d \d\d\d \d\d\d-\d\d-\d\d$/;

const InputPhone: ForwardRefRenderFunction<any, InputPhoneProps> = (
  props,
  ref
) => {
  return (
    <InputMask
      mask="+7 999 999-99-99"
      placeholder="+7 123 456 78 90"
      maskChar=""
      type="tel"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {(rest: any) => (
        <Input
          ref={ref}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      )}
    </InputMask>
  );
};

export default forwardRef(InputPhone);
