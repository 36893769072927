import { Car } from 'azgaz-connect';

type Requisites = {
  bank_bik: string;
  bank_correspondent_account: string;
  bank_name: string;
  bank_fullname: string;
  bank_inn: string;
  bank_kpp: string;
  bank_checking_account: string;
  consigner_address: string;
  consigner_inn: string;
  consigner_kpp: string;
  consigner_name: string;
  consigner_phone: string;
  contract_offer: string;
  contract_offer_personal: string;
  person_head_organization: string;
  person_in_charge: string;
  prepayment: number;
  provider_address: string;
  provider_inn: string;
  provider_kpp: string;
  provider_name: string;
  provider_phone: string;
  signature_right_based: string;
  show_order_form: boolean;
};

export type Office = {
  id: string;
  requisites?: Partial<Requisites>;
  name: string;
  // TODO: уточнить тип
};

export default (car: Car | undefined): Office[] | undefined => {
  if (!car) return;

  const dealerRequisites: Office[] | undefined = car?.dealer?.offices;

  /* eslint-disable consistent-return */
  return dealerRequisites;
};
