import React from 'react';

type TOpts = {
  name: string;
  hex?: string;
};

const renderColorOption = (opts: TOpts) => {
  const { name, hex } = opts;

  const hexColor =
    hex &&
    hex
      .split(';')
      .map(color => `#${color}`)
      .join(',');

  return (
    <div className="aui-ColorOption">
      {hexColor && (
        <span
          style={{
            background: `linear-gradient( -45deg, ${hexColor})`,
          }}
        />
      )}
      {name}
    </div>
  );
};

export default renderColorOption;
