/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { CarTitle as AuiCarTitle } from '@azgaz/azgaz-ui';
import { Components } from '@/apps/appCreator/outsideComponents';

const CarTitle: Components['CarTitle'] = ({ car }) => {
  const { dealer, office_ids } = car;
  const currentOffice = dealer.offices.find(
    ({ id }: { id: string }) => id === office_ids[0]
  );
  const dealerInfo =
    currentOffice && `${currentOffice.name}, ${currentOffice.city}`;

  return (
    <AuiCarTitle title={`${car.model} ${car.name}`} dealerInfo={dealerInfo} />
  );
};

export default CarTitle;
