import { themeContext } from 'azgaz-ui';
import appCreator from '../appCreator';
import { CarsFilters } from './components';
import filtersConfig from './filtersConfig';

export default appCreator({
  filtersConfig,
  azgazUiTheme: themeContext.azgazTheme,
  components: {
    CarsFilters,
    // CarsItemDealer,
    // CarTitle,
  },
});
