/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect } from 'react';
import cn from 'classnames';

import {
  Select,
  Option,
  RangeSlider,
  CarsFilters as AuiCarsFilters,
} from 'azgaz-ui';

import {
  useSelector,
  selectors,
  utils,
  useDispatch,
  Ranges,
} from 'azgaz-connect';

import { config } from '@/utils';
import filtersConfig from '../../filtersConfig';

type CarsFiltersProps = {
  form: any;
  ranges: Ranges;
};

const tooltipOverlay = (tooltipProps: any) => {
  return tooltipProps.value;
};

const CarsFilters: FC<CarsFiltersProps> = ({ form, ranges }) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectors.getFiltersValues);
  const cars = useSelector(selectors.getCars);
  const isFiltersLoading = useSelector(selectors.getFiltersLoading);
  const { year: yearRange, price: priceRange } = ranges || {};

  const isCarGroupsNew = config
    .get('carGroups')
    .find(({ id }: { id: string }) => id === 'new');

  const uiHelper = new utils.UiHelper({
    filters,
    form,
    dispatch,
  });

  useEffect(() => {
    if (!cars) {
      uiHelper.initFilters();
    }
  }, []);

  const [
    onModelChange,
    onGradeChange,
    onUseChange,
    onDriveTypeChange,
    onEngineTypeChange,
    onPassangersChange,
  ] = uiHelper.createOnChange([
    filtersConfig.model,
    filtersConfig.grade,
    filtersConfig.use,
    filtersConfig.driveType,
    filtersConfig.engineType,
    filtersConfig.passangers,
  ]);

  const [onYearChange, onPriceChange] = uiHelper.createDebounceOnChange([
    filtersConfig.year,
    filtersConfig.price,
  ]);

  const { getFieldProps } = form;

  const useValues = uiHelper
    .getDefaultFilter(filtersConfig.use.path)
    .filter(({ value }) => value);

  const modelValues = uiHelper
    .getDefaultFilter(filtersConfig.model.path)
    .filter(({ value }) => value);

  const gradeValues = uiHelper
    .getDefaultFilter(filtersConfig.grade.path)
    .filter(({ value }) => value);

  const driveTypeValues = uiHelper
    .getDefaultFilter(filtersConfig.driveType.path)
    .filter(({ value }) => value);

  const engineTypeValues = uiHelper
    .getDefaultFilter(filtersConfig.engineType.path)
    .filter(({ value }) => value);

  const passangersValues = uiHelper
    .getDefaultFilter(filtersConfig.passangers.path)
    .filter(({ value }) => value);

  return (
    <AuiCarsFilters
      title={config.get('carsTitle')}
      isLoading={isFiltersLoading}
      onFiltersReset={uiHelper.resetFilters}
    >
      {filters?.length && yearRange && (
        <div className="row">
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Автомобиль"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              disabled={!modelValues.length}
              {...getFieldProps(filtersConfig.model.name, {
                onChange: onModelChange,
              })}
            >
              {modelValues.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Модель"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              disabled={!gradeValues.length}
              {...getFieldProps(filtersConfig.grade.name, {
                onChange: onGradeChange,
              })}
            >
              {gradeValues.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Назначение"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              disabled={!useValues.length}
              {...getFieldProps(filtersConfig.use.name, {
                onChange: onUseChange,
              })}
            >
              {useValues.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Тип привода"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              disabled={!driveTypeValues.length}
              {...getFieldProps(filtersConfig.driveType.name, {
                onChange: onDriveTypeChange,
              })}
            >
              {driveTypeValues.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Тип двигателя"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              disabled={!engineTypeValues.length}
              {...getFieldProps(filtersConfig.engineType.name, {
                onChange: onEngineTypeChange,
              })}
            >
              {engineTypeValues.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </div>
          {/* Скрываем "Год выпуска" для новых авто */}
          {!isCarGroupsNew && (
            <div className="col-sm-6 col-md-3 mb-5 mb-md-4">
              <RangeSlider
                title="Год выпуска"
                step={1}
                space={12}
                spade={8}
                tooltipOverlay={tooltipOverlay}
                {...yearRange}
                {...getFieldProps(filtersConfig.year.name, {
                  onChange: onYearChange,
                  initialValue: [yearRange.min, yearRange.max],
                })}
              />
            </div>
          )}
          {/* Выводим "Цена" последним для новых авто */}
          <div
            className={cn('col-sm-12 col-md-6 mb-5 mb-md-4', {
              'order-1': isCarGroupsNew,
            })}
          >
            <RangeSlider
              title="Стоимость"
              space={12}
              spade={8}
              tooltipOverlay={tooltipOverlay}
              {...priceRange}
              {...getFieldProps(filtersConfig.price.name, {
                onChange: onPriceChange,
                initialValue: [priceRange.min, priceRange.max],
              })}
            />
          </div>
          <div className="col-sm-6 col-md-3 mb-4">
            <Select
              title="Пассажировместимость"
              placeholder="Все"
              allowClear
              optionLabelProp="children"
              showSearch={false}
              disabled={!passangersValues.length}
              {...getFieldProps(filtersConfig.passangers.name, {
                onChange: onPassangersChange,
              })}
            >
              {passangersValues.map(({ title, value }) => (
                <Option key={value} value={value}>
                  {title}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      )}
    </AuiCarsFilters>
  );
};

export default utils.withForm(CarsFilters);
