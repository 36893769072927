/* eslint-disable import/no-cycle */
import React, { FC, useContext } from 'react';

import { Car } from 'azgaz-connect';
import { CarsItem } from 'azgaz-ui';
import { PagePathnameContext } from '@/apps/appCreator/components/CarsFallbackList';

type TProps = {
  car: Car;
};

const CarsItemWrap: FC<TProps> = ({ car, children }) => {
  const pathnameLink = useContext(PagePathnameContext);
  const { id } = car;

  if (pathnameLink) {
    return (
      <div className="col-md-6 col-lg-4 d-md-flex">
        <a href={`${pathnameLink}${id}/`} className="azgazui__cars-item">
          {children}
        </a>
      </div>
    );
  }

  return (
    <CarsItem id={id}>
      <>{children}</>
    </CarsItem>
  );
};

export default CarsItemWrap;
