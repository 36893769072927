import { createSelector } from '@reduxjs/toolkit';
import { getSpecificationsForGroup, numberFormat } from 'azgaz-ui';
import { resolveFsFields } from '@/utils';
import getOrderFullInfo from './getOrderFullInfo';
import getOrderPdfLink from './getOrderPdfLink';
import getCarPriceWithDiscounts from './getCarPriceWithDiscounts';
import getSelectedServices from './getSelectedServices';

export default createSelector(
  getOrderFullInfo,
  getOrderPdfLink,
  getCarPriceWithDiscounts,
  (state, car) => {
    const mainSpecifications = getSpecificationsForGroup(
      car?.specification,
      'main',
      ['modification', 'use', 'drive_type', 'engine_type', 'passangers']
    );

    const otherSpecifications = getSpecificationsForGroup(
      car?.specification,
      'other',
      ['dealer_comment']
    );
    const selectedServices = getSelectedServices(state);

    return {
      carYear: car?.year?.toString(),
      carModification: mainSpecifications.modification,
      carUse: mainSpecifications.use,
      carDriveType: mainSpecifications.drive_type,
      carEngineType: mainSpecifications.engine_type,
      carPassengers: mainSpecifications.passangers?.toString(),
      carDealerComment: otherSpecifications.dealer_comment,
      services: selectedServices.join(','),
    };
  },
  (info, orderLink, carPriceWithDiscounts, additionalFields) => {
    if (!info) return;

    let formFields: { [K: string]: any } = {
      userName: info.userName,
      userSurname: info.userSurname,
      userPatronymic: info.userPatronymic,
    };

    // company mode
    if (info.userType === 'company') {
      formFields = {
        userFIO: info.userFIO,
        companyName: info.companyName,
        companyAddress: info.companyAddress,
        companyPhone: info.companyPhone,
        inn: Number(info.inn),
        kpp: Number(info.kpp),
      };
    }

    // eslint-disable-next-line consistent-return
    return resolveFsFields({
      family: info.family,
      vin: info.vin,
      userType: info.userType,
      userPhone: info.userPhone,
      userEmail: info.userEmail,
      userComment: info.userComment,
      termPersonalData: info.termPersonalData,
      termGettingInfo: info.termGettingInfo,
      termRulesInfo: info.termRulesInfo,
      termOrderAgreement: info.termOrderAgreement,
      orderLink,
      carDiscountPrice: numberFormat({
        style: 'currency',
        currency: 'RUB',
      })(carPriceWithDiscounts),
      carPrice: numberFormat({
        style: 'currency',
        currency: 'RUB',
      })(info.carPriceInfoPrice),
      ...additionalFields,
      ...formFields,
    });
  }
);
