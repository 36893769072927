import { createSelector } from '@reduxjs/toolkit';
import { utils } from 'azgaz-connect';
import { config } from '@/utils';
import { fields } from '@/apps/appCreator/store/order';
import getOrderFullInfo from './getOrderFullInfo';

export default createSelector(getOrderFullInfo, orderData => {
  if (!orderData) return;

  /**
   * Удаляем поле userComment, т.к. оно не участвует в формировании pdf,
   * так же это поле при наличии символа 'enter' ломает сервис на бэке.
   */
  const resolvedOrderData = {
    ...orderData,
    [fields.userComment]: undefined,
  };
  const query = utils.urlQueryString.stringify(resolvedOrderData, {
    encode: false,
  });
  const link = `${config.get('orderApi')}${query}`;
  // eslint-disable-next-line consistent-return
  return link;
});
